import React from 'react'

const Summary = (props) => {
    return (
        <div className="row">
            {
                
                props.OptSummarydict.map((item, index) => {
                    return (
                        <div key={index} className="col-sm-6" >
                            <div className="card text-center summary-box" style={{border:'none'}}>
                                <div className={item.highlight === 1 ? "tag-class" :  "tag-class"} >
                                    <div className="summary-box-content" style={{height:'max-content'}}>
                                        <h5 className="py-1 m-0" dangerouslySetInnerHTML={{ __html: item.header }} 
                                        // style={{fontSize: "10px"}}
                                        ></h5>
                                        <p className="m-0 summary-p" 
                                        // style={{fontSize: "10px"}}
                                        >{item.value}</p>
                                        <p className="p-1 m-0 summary-footnote" 
                                        // style={{fontSize: "10px"}}
                                        >{item.footnote}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Summary