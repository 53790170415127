import React, { useEffect, useState } from "react";
import Topbar from "../Components/Topbar";
import Menubar from "../Components/Menubar";
import axios from "../Axios";
import Loader from "../Components/Loader";
import { useLocation } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Measurement from "../Components/Measurement";
import Comparison from "../Components/Comparison";

import AllHeirarchyFilter from "../Components/HeirarchyFilter"; // H0, H1, etc
import AllCampaignFilter from "../Components/ChannelFilter"; // C0, C1, ect
import TrendAnalysis from "../Components/TrendAnalyis";

import LightThemeHexCode from "../Components/LightThemeColorCode";
import useDownloadChart from "../Components/DownloadCharts";
import HeirarchyFilterComp from "../Components/HeirarchyFilterComp";
import ChannelFilterComp from "../Components/ChannelFilterComp";
import axiosDownload from "../Axios";
import axiosChartDownload from "../Axios";
import domtoimage from "dom-to-image";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";

const Main = () => {
  const location = useLocation();
  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  const DefaultLevel = JSON.parse(localStorage.getItem("DefaultLevel"));
  // console.log("Analyse: ", Analyse);
  const [loader, setLoader] = useState(false);
  const [ActiveChart, setActiveChart] = useState("Measurement");
  // console.log("ActiveChart: ", ActiveChart);

  const [NotAllow, setNotAllow] = useState([]);
  // console.log("NotAllow: ", NotAllow);

  const [submittedStartDate, setSubmittedStartDate] = useState("");
  const [submittedEndDate, setSubmittedEndDate] = useState("");
  const [submittedTimeFrame, setSubmittedTimeFrame] = useState("");

  // measurement
  const [TimeFramesAll, setTimeFramesAll] = useState([]);
  const [FromDatesAll, setFromDatesAll] = useState([]);
  const [ToDatesAll, setToDatesAll] = useState([]);
  const [timeframe, setTimeframe] = useState("");
  const [DefaultStartDate, setDefaultStartDate] = useState("");
  const [DefaultEndDate, setDefaultEndDate] = useState("");

  // trends chart
  const [TimeFramesAllTrends, setTimeFramesAllTrends] = useState([]);
  const [FromDatesAllTrends, setFromDatesAllTrends] = useState([]);
  const [ToDatesAllTrends, setToDatesAllTrends] = useState([]);
  const [timeframeTrends, setTimeframeTrends] = useState("");
  const [DefaultStartDateTrends, setDefaultStartDateTrends] = useState("");
  const [DefaultEndDateTrends, setDefaultEndDateTrends] = useState("");
  const [showLegend, setShowLegend] = useState(false);

  // console.log("DefaultStartDateTrends: ", DefaultStartDateTrends, DefaultEndDateTrends);

  const [TimeFramesAllComp1, setTimeFramesAllComp1] = useState([]);
  const [FromDatesAllComp1, setFromDatesAllComp1] = useState([]);
  const [ToDatesAllComp1, setToDatesAllComp1] = useState([]);
  const [timeframeComp1, setTimeframeComp1] = useState("");
  const [DefaultStartDateComp1, setDefaultStartDateComp1] = useState("");
  const [DefaultEndDateComp1, setDefaultEndDateComp1] = useState("");

  const [TimeFramesAllComp2, setTimeFramesAllComp2] = useState([]);
  const [FromDatesAllComp2, setFromDatesAllComp2] = useState([]);
  const [ToDatesAllComp2, setToDatesAllComp2] = useState([]);
  const [timeframeComp2, setTimeframeComp2] = useState("");
  const [DefaultStartDateComp2, setDefaultStartDateComp2] = useState("");
  const [DefaultEndDateComp2, setDefaultEndDateComp2] = useState("");

  const [ZoomIn, setZoomIn] = useState(false);

  const AnalyticFilter = JSON.parse(localStorage.getItem("AnalyticFilter"));
  // console.log("AnalyticFilter: ", AnalyticFilter);
  const CampaignAnalyticFilter = JSON.parse(
    localStorage.getItem("CampaignAnalyticFilter")
  );
  const [AnalyticFilterFlag, setAnalyticFilterFlag] = useState(true);

  const [FilterTabShow, setFilterTabShow] = useState(false);
  const Fixed = localStorage.getItem("AnalyticFilter")
    ? AnalyticFilter.Fixed
    : [];
  // console.log("Fixed: ", Fixed);
  const [changeFilter, setChangeFilter] = useState(false);
  //Measurement Chart
  const [AllFilters, setAllFilters] = useState([]);
  // console.log("AllFilters: ", AllFilters);
  const [ActiveFilters, setActiveFilters] = useState([]);
  // console.log("ActiveFilters: ", ActiveFilters);

  const [ClickedCategory, setClickedCategory] = useState(null);
  // console.log("ClickedCategory: ", ClickedCategory);
  const [Chartdata, setChartdata] = useState({});
  // console.log("Chartdata: ", Chartdata);
  const [BreadCrumbs, setBreadCrumbs] = useState([]);
  // console.log("BreadCrumbs: ", BreadCrumbs);
  const [categories, setCategories] = useState([]);
  // console.log("categories: ", categories);
  const [series, setSeries] = useState([]);
  const [Summary, setSummary] = useState([]);
  const [FetchCall, setFetchCall] = useState(true);
  // const [FetchCallTrend, setFetchCallTrend] = useState(true);
  const [IsAllEnabled, setIsAllEnabled] = useState(0);
  // console.log("IsAllEnabled: ", IsAllEnabled);
  const [Reset, setReset] = useState(0);
  const [charttitle, setcharttitle] = useState("");
  const [xtitle, setxtitle] = useState("");
  const [ytitle, setytitle] = useState("");

  // trends chart
  const [BreadCrumbsTrends, setBreadCrumbsTrends] = useState([]);
  // console.log("BreadCrumbsTrends: ", BreadCrumbsTrends);
  const [categoriesTrends, setCategoriesTrends] = useState([]);
  const [seriesTrends, setSeriesTrends] = useState([]);
  const [FetchCallTrends, setFetchCallTrends] = useState(false);
  const [charttitleTrends, setcharttitleTrends] = useState("");
  const [xtitleTrends, setxtitleTrends] = useState("");
  const [ytitleTrends, setytitleTrends] = useState("");
  const [ChartdataTrends, setChartdataTrends] = useState({});

  //Comparison Chart 1
  const [BreadCrumbsComp1, setBreadCrumbsComp1] = useState([]);
  // console.log("BreadCrumbsComp1: ", BreadCrumbsComp1);
  const [categoriesComp1, setCategoriesComp1] = useState([]);
  const [seriesComp1, setSeriesComp1] = useState([]);
  const [FetchCallComp1, setFetchCallComp1] = useState(true);
  const [charttitleComp1, setcharttitleComp1] = useState("");
  const [xtitleComp1, setxtitleComp1] = useState("");
  const [ytitleComp1, setytitleComp1] = useState("");
  const [ChartdataComp1, setChartdataComp1] = useState({});
  // console.log("ChartdataComp1: ", ChartdataComp1);
  const [ClickedCategoryComp1, setClickedCategoryComp1] = useState(null);

  //Comparison Chart 2
  const [BreadCrumbsComp2, setBreadCrumbsComp2] = useState([]);
  const [categoriesComp2, setCategoriesComp2] = useState([]);
  const [seriesComp2, setSeriesComp2] = useState([]);
  const [FetchCallComp2, setFetchCallComp2] = useState(true);
  const [charttitleComp2, setcharttitleComp2] = useState("");
  const [xtitleComp2, setxtitleComp2] = useState("");
  const [ytitleComp2, setytitleComp2] = useState("");
  const [ChartdataComp2, setChartdataComp2] = useState({});
  // console.log("ChartdataComp2: ", ChartdataComp2);
  const [ClickedCategoryComp2, setClickedCategoryComp2] = useState(null);

  //Comp filters state
  const [CompAllFilters, setCompAllFilters] = useState([]);
  const [CompActiveFilters, setCompActiveFilters] = useState([]);
  // console.log("CompActiveFilters: ", CompActiveFilters);

  // const [Downloadchart, setDownloadchart] = useState(false)
  const [ShowSummary, setShowSummary] = useState(0);
  const [FooterNotes, setFooterNotes] = useState("");
  const [MResetFilterFlag, setMResetFilterFlag] = useState(false);
  const [CResetFilterFlag, setCResetFilterFlag] = useState(false);

  const [isDownloadingChart, setIsDownloadingChart] = useState(false);
  const [chartContainerID, setChartContainerID] = useState("");
  const [downloadedImageName, setDownloadedImageName] = useState("");
  const [downloadingChartTitle, setDownloadingChartTitle] = useState("");
  const [Heirarchy, setHierarchy] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [timeFrameChart, setTimeFrameChart] = useState("");

  const userName = localStorage.getItem("NameSkewb");
  const [IsAllEnabledDownload, setIsAllEnabledDownload] = useState(0);
  const [IsAllEnabledDownloadC2, setIsAllEnabledDownloadC2] = useState(0);

  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");

  const [level, setLevel] = useState(null);

  const [isReadyChartdata, setIsReadyChartdata] = useState(false);

  const [newHierarchyBreadCrumb, setNewHierarchyBreadCrumb] = useState("");
  const [newChannelBreadCrumb, setNewChannelBreadCrumb] = useState("");

  const [newHierarchyBreadCrumb2, setNewHierarchyBreadCrumb2] = useState("");
  const [newChannelBreadCrumb2, setNewChannelBreadCrumb2] = useState("");

  const [hierarchySelectionBreadCrumb, setHierarchySelectionBreadCrumb] =
    useState("");
  const [channelSelectionBreadCrumb, setChannelSelectionBreadCrumb] =
    useState("");

  // console.log("IsAllEnabledDownload: ", IsAllEnabledDownload);
  // console.log("IsAllEnabledDownloadC2: ", IsAllEnabledDownloadC2);

  useEffect(() => {
    setLevel(DefaultLevel);
  }, [ActiveChart]);

  const ChartdataForHierarchyFilters = () => {
    if (ActiveChart === "Measurement") {
      return [Chartdata];
    } else if (ActiveChart === "Comparison") {
      return [ChartdataComp1, ChartdataComp2];
    } else {
      return [ChartdataTrends];
    }
  };

  const returnSetChartdata = () => {
    if (ActiveChart === "Measurement") {
      return [setChartdata];
    } else if (ActiveChart === "Comparison") {
      return [setChartdataComp1, setChartdataComp2];
    } else {
      return [setChartdataTrends];
    }
  };

  const returnSetFetchCall = () => {
    if (ActiveChart === "Measurement") {
      return [setFetchCall];
    } else if (ActiveChart === "Comparison") {
      return [setFetchCallComp1, setFetchCallComp2];
    } else {
      return [setFetchCallTrends];
    }
  };

  useEffect(() => {
    setIsAllEnabledDownload(0);
    setIsAllEnabledDownloadC2(0);
  }, [ActiveChart]);

  // useEffect(() => {
  //   console.log("1: ", ChartdataForHierarchyFilters());
  //   console.log("2: ", returnSetChartdata());
  //   console.log("3: ", returnSetFetchCall());
  // }, [ActiveChart]);

  useEffect(() => {
    setFetchCall(true);
    setFetchCallComp1(true);
    setFetchCallComp2(true);
    setFetchCallTrends(true);
  }, [Analyse.Brand, Analyse.KPI, ActiveChart]);

  let lastArgument;

  if (ActiveChart === "Measurement") {
    lastArgument = Chartdata;
  } else if (ActiveChart === "Comparison") {
    lastArgument = ChartdataComp1;
  } else if (ActiveChart === "Comparison") {
    lastArgument = ChartdataComp2;
  } else if (ActiveChart === "Trend_Analysis") {
    lastArgument = ChartdataTrends;
  }

  useDownloadChart(
    downloadedImageName,
    downloadingChartTitle,
    isDownloadingChart,
    chartContainerID,
    "",
    Heirarchy,
    setLoader,
    Analyse.Brand,
    timeFrameChart,
    startDate,
    endDate,
    setIsDownloadingChart,
    Analyse.KPI,
    lastArgument
  );

  useEffect(() => {
    const localAnalyticFilter = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    );
    const localCampaignAnlayticFilter = JSON.parse(
      localStorage.getItem("StoreCampaignAnalyticFilter")
    );
    localStorage.setItem(
      "CampaignAnalyticFilter",
      JSON.stringify(localCampaignAnlayticFilter)
    );
    localStorage.setItem("AnalyticFilter", JSON.stringify(localAnalyticFilter));
    setAllFilters(localAnalyticFilter.AllFilters);
    setActiveFilters(localAnalyticFilter.ActiveFilters);
    setAnalyticFilterFlag(false);
    setCompActiveFilters(localCampaignAnlayticFilter.CompActiveFilters);
    setCompAllFilters(localCampaignAnlayticFilter.CompAllFilters);
    setNewHierarchyBreadCrumb("");
    setNewHierarchyBreadCrumb2("");
    setNewChannelBreadCrumb("");
    setNewChannelBreadCrumb2("");
  }, [Analyse.Brand, ActiveChart, Analyse.KPI]);

  const onChangeHandler = (e) => {
    const value = e.target.value;
    for (var i = 0; i < TimeFramesAll.length; i++) {
      if (TimeFramesAll[i].key === value) {
        setFromDatesAll(TimeFramesAll[i].fromdates);
        setToDatesAll(TimeFramesAll[i].todates);
        setDefaultStartDate(TimeFramesAll[i].default_startdate); // added this line by Naeem
        setDefaultEndDate(TimeFramesAll[i].default_enddate); // added this line by Naeem
      }
    }
    setTimeframe(value);
    setFetchCall(true);
  };

  const onChangeHandlerComp1 = (e) => {
    const value = e.target.value;
    for (var i = 0; i < TimeFramesAllComp1.length; i++) {
      if (TimeFramesAllComp1[i].key === value) {
        setFromDatesAllComp1(TimeFramesAllComp1[i].fromdates);
        setToDatesAllComp1(TimeFramesAllComp1[i].todates);
        setDefaultStartDateComp1(TimeFramesAllComp1[i].default_startdate); // added this line by Naeem
        setDefaultEndDateComp1(TimeFramesAllComp1[i].default_enddate); // added this line by Naeem
      }
    }
    setTimeframeComp1(value);
    setFetchCallComp1(true);
  };

  const onChangeHandlerComp2 = (e) => {
    const value = e.target.value;
    for (var i = 0; i < TimeFramesAllComp2.length; i++) {
      if (TimeFramesAllComp2[i].key === value) {
        setFromDatesAllComp2(TimeFramesAllComp2[i].fromdates);
        setToDatesAllComp2(TimeFramesAllComp2[i].todates);
        setDefaultStartDateComp2(TimeFramesAllComp2[i].default_startdate); // added this line by Naeem
        setDefaultEndDateComp2(TimeFramesAllComp2[i].default_enddate); // added this line by Naeem
      }
    }
    setTimeframeComp2(value);
    setFetchCallComp2(true);
  };

  const onChangeHandlerTrends = (e) => {
    const value = e.target.value;
    for (var i = 0; i < TimeFramesAllTrends.length; i++) {
      if (TimeFramesAllTrends[i].key === value) {
        setFromDatesAllTrends(TimeFramesAllTrends[i].fromdates);
        setToDatesAllTrends(TimeFramesAllTrends[i].todates);
        setDefaultStartDateTrends(TimeFramesAllTrends[i].default_startdate); // added this line by Naeem
        setDefaultEndDateTrends(TimeFramesAllTrends[i].default_enddate); // added this line by Naeem
      }
    }
    setTimeframeTrends(value);
    setFetchCallTrends(true);
  };

  useEffect(() => {
    setLoader(true);
    axios
      .post("get_timeframes/", {
        page: "grain_contri",
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
      })
      .then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          //setLoader(false);
        } else {
          setTimeFramesAll(response.data.data);
          setTimeframe(response.data.data[0].key);
          setFromDatesAll(response.data.data[0].fromdates);
          setToDatesAll(response.data.data[0].todates);
          setDefaultStartDate(response.data.data[0].default_startdate);
          setDefaultEndDate(response.data.data[0].default_enddate);

          setTimeFramesAllComp1(response.data.data);
          setTimeframeComp1(response.data.data[0].key);
          setFromDatesAllComp1(response.data.data[0].fromdates);
          setToDatesAllComp1(response.data.data[0].todates);
          setDefaultStartDateComp1(response.data.data[0].default_startdate);
          setDefaultEndDateComp1(response.data.data[0].default_enddate);

          setTimeFramesAllComp2(response.data.data);
          setTimeframeComp2(response.data.data[0].key);
          setFromDatesAllComp2(response.data.data[0].fromdates);
          setToDatesAllComp2(response.data.data[0].todates);
          setDefaultStartDateComp2(response.data.data[0].default_startdate);
          setDefaultEndDateComp2(response.data.data[0].default_enddate);

          setSubmittedTimeFrame(response.data.data[0].key);
          setSubmittedStartDate(response.data.data[0].default_startdate);
          setSubmittedEndDate(response.data.data[0].default_enddate);

          setTimeFramesAllTrends(response.data.data);
          setTimeframeTrends(response.data.data[0].key);
          setFromDatesAllTrends(response.data.data[0].fromdates);
          setToDatesAllTrends(response.data.data[0].todates);
          setDefaultStartDateTrends(response.data.data[0].default_startdate);
          setDefaultEndDateTrends(response.data.data[0].default_enddate);
          setStartdate(response.data.data[0].start_date);
          setEnddate(response.data.data[0].end_date);

          //setLoader(false);
        }
      })
      .catch((data) => {
        //setLoader(false);
      });
  }, [Analyse.Brand, Analyse.KPI, ActiveChart]);

  useEffect(() => {
    if (FetchCall && ActiveChart === "Measurement") {
      if (
        timeframe !== "" &&
        DefaultStartDate !== "" &&
        DefaultEndDate !== "" &&
        JSON.stringify(Chartdata) !== "{}"
      ) {
        if (
          FromDatesAll?.indexOf(DefaultStartDate) >
          ToDatesAll?.indexOf(DefaultEndDate)
        ) {
          toast.error("Please enter valid time period");
          setFetchCall(false);
        } else {
          setLoader(true);
          setCategories([]);
          setSeries([]);
          axios
            .post("get_grain_contrib_new/", {
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              timeframe: timeframe,
              startdate: DefaultStartDate,
              enddate: DefaultEndDate,
              is_all_enabled: IsAllEnabled,
              reset: Reset,
              chartdata: Chartdata,
              level: level,
              theme: localStorage.getItem("theme"),
              LightThemeColorCode: [
                localStorage.getItem("ColorCode"),
                LightThemeHexCode[localStorage.getItem("ColorCode")],
              ],
            })
            .then((response) => {
              if (response.data.error === 1) {
                // toast.error(response.data.erroMsg);
                toast.error(
                  "No data found for the following filter, please reset the filter"
                );
                //setLoader(false);
              } else {
                setNotAllow(response.data.data.nonclicklist);
                setCategories(response.data.data.categories);
                setSeries(response.data.data.series);
                setSummary(response.data.data.summary || []);
                setFooterNotes(response.data.data.footernotes);
                setcharttitle(response.data.data.charttitle);
                setxtitle(response.data.data.xtitle);
                setytitle(response.data.data.ytitle);
                if (response.data.data.show_summary !== undefined) {
                  setShowSummary(response.data.data.show_summary);
                }
                //setLoader(false);
              }
              setIsAllEnabled(0);
              setReset(0);
              setFetchCall(false);
              setIsReadyChartdata(false);
            })
            .catch((data) => {
              //setLoader(false);
            });
        }
      }
    }
  }, [
    Analyse.Brand,
    Analyse.KPI,
    timeframe,
    DefaultStartDate,
    DefaultEndDate,
    FetchCall,
    FromDatesAll,
    ToDatesAll,
    Chartdata,
    ActiveFilters,
    ActiveChart,
    IsAllEnabled,
    Reset,
  ]);

  useEffect(() => {
    const controller = new AbortController(); // Create an AbortController instance
    const signal = controller.signal;
    if (FetchCallComp1 && ActiveChart === "Comparison") {
      console.log("COMP1 api call ", ChartdataComp1);
      if (
        timeframeComp1 !== "" &&
        DefaultStartDateComp1 !== "" &&
        DefaultEndDateComp1 !== "" &&
        ChartdataComp1.H0.Filter.length > 0
      ) {
        setLoader(true);
        setCategoriesComp1([]);
        setSeriesComp1([]);
        axios
          .post(
            "get_grain_contrib_new/",
            {
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              timeframe: timeframeComp1,
              startdate: DefaultStartDateComp1,
              enddate: DefaultEndDateComp1,
              chartdata: ChartdataComp1,
              is_all_enabled: IsAllEnabled,
              reset: Reset,
              level: level,
              chart: "comp-1",
              theme: localStorage.getItem("theme"),
              LightThemeColorCode: [
                localStorage.getItem("ColorCode"),
                LightThemeHexCode[localStorage.getItem("ColorCode")],
              ],
            },
            { signal } // Pass the signal to the request
          )
          .then((response) => {
            if (response.data.error === 1) {
              // toast.error(response.data.erroMsg);
              toast.error(
                "No data found for the following filter, please reset the filter"
              );
              //setLoader(false);
            } else {
              // setCategories(get_grain.data.categories);
              // setSeries(get_grain.data.series);
              setNotAllow(response.data.data.nonclicklist);
              setCategoriesComp1(response.data.data.categories);
              setSeriesComp1(response.data.data.series);
              // setSummary(response.data.data.summary || []);
              setFooterNotes(response.data.data.footernotes);
              setcharttitleComp1(response.data.data.charttitle);
              setxtitleComp1(response.data.data.xtitle);
              setytitleComp1(response.data.data.ytitle);
              //setLoader(false);
            }
            setIsAllEnabled(0);
            setReset(0);
            setFetchCallComp1(false);
          })
          .catch((data) => {
            //setLoader(false);
          });
      }
    }
    return () => {
      controller.abort(); // Cancel the Axios request
    };
  }, [
    // Analyse.Brand,
    // Analyse.KPI,
    timeframeComp1,
    DefaultStartDateComp1,
    DefaultEndDateComp1,
    FetchCallComp1,
    ChartdataComp1,
    ActiveChart,
    // IsAllEnabled,
    // Reset,
  ]);

  useEffect(() => {
    const controller = new AbortController(); // Create an AbortController instance
    const signal = controller.signal;
    if (FetchCallComp2 && ActiveChart === "Comparison") {
      if (
        timeframeComp2 !== "" &&
        DefaultStartDateComp2 !== "" &&
        DefaultEndDateComp2 !== "" &&
        ChartdataComp2.H0.Filter.length > 0
      ) {
        setLoader(true);
        setCategoriesComp2([]);
        setSeriesComp2([]);
        axios
          .post(
            "get_grain_contrib_new/",
            {
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              timeframe: timeframeComp2,
              startdate: DefaultStartDateComp2,
              enddate: DefaultEndDateComp2,
              chartdata: ChartdataComp2,
              is_all_enabled: IsAllEnabled,
              reset: Reset,
              level: level,
              chart: "comp-2",
              theme: localStorage.getItem("theme"),
              LightThemeColorCode: [
                localStorage.getItem("ColorCode"),
                LightThemeHexCode[localStorage.getItem("ColorCode")],
              ],
            },
            { signal } // Pass the signal to the request
          )
          .then((response) => {
            if (response.data.error === 1) {
              // toast.error(response.data.erroMsg);
              toast.error(
                "No data found for the following filter, please reset the filter"
              );
              //setLoader(false);
            } else {
              setNotAllow(response.data.data.nonclicklist);
              setCategoriesComp2(response.data.data.categories);
              setSeriesComp2(response.data.data.series);
              // setSummary(response.data.data.summary || []);
              setFooterNotes(response.data.data.footernotes);
              setcharttitleComp2(response.data.data.charttitle);
              setxtitleComp2(response.data.data.xtitle);
              setytitleComp2(response.data.data.ytitle);
              //setLoader(false);
            }
            setIsAllEnabled(0);
            setReset(0);
            setFetchCallComp2(false);
          })
          .catch((data) => {
            //setLoader(false);
          });
      }
    }
    return () => {
      controller.abort(); // Cancel the Axios request
    };
  }, [
    // Analyse.Brand,
    // Analyse.KPI,
    timeframeComp2,
    DefaultStartDateComp2,
    DefaultEndDateComp2,
    FetchCallComp2,
    ChartdataComp2,
    ActiveChart,
    // IsAllEnabled,
    // Reset,
  ]);

  // for trend analysis
  // "get_contributions_stack/"
  // const URL = "http://15.207.123.147:8000/chartdata/"
  useEffect(() => {
    console.log("result Trend Analysis useEffect ", ChartdataTrends, " ");
    if (FetchCallTrends && ActiveChart === "Trend_Analysis") {
      if (
        timeframeTrends !== "" &&
        DefaultStartDateTrends !== "" &&
        DefaultEndDateTrends !== "" &&
        JSON.stringify(ChartdataTrends) !== "{}"
      ) {
        setLoader(true);
        setCategoriesTrends([]);
        setSeriesTrends([]);
        axios
          .post("get_contributions_stack/", {
            brand: Analyse.Brand,
            kpi: Analyse.KPI,
            timeframe: timeframeTrends,
            startdate: DefaultStartDateTrends,
            enddate: DefaultEndDateTrends,
            is_all_enabled: IsAllEnabled,
            level: level,
            reset: Reset,
            chartdata: ChartdataTrends,
            theme: localStorage.getItem("theme"),
          })
          .then((response) => {
            // console.log("Trends Chart Data: ", response.data);
            if (response.data.error === 1) {
              // toast.error(response.data.data.erroMsg);
              toast.error(
                "No data found for the following filter, please reset the filter"
              );
              //setLoader(false);
            } else {
              setNotAllow(response.data.data.nonclicklist);
              // setCategoriesTrends(response.data.data.categories);
              setCategoriesTrends(response.data.data.display_date);
              setSeriesTrends(response.data.data.series);
              setSummary(response.data.data.summary || []);
              setFooterNotes(response.data.data.footernotes);
              setcharttitleTrends(response.data.data.charttitle);
              setxtitleTrends(response.data.data.xtitle);
              setytitleTrends(response.data.data.ytitle);
              setShowLegend(response.data.data.show_legend);
              if (response.data.data.show_summary !== undefined) {
                setShowSummary(response.data.data.show_summary);
              }
              //setLoader(false);
            }
            setIsAllEnabled(0);
            setReset(0);
            setFetchCallTrends(false);
          })
          .catch((data) => {
            //setLoader(false);
          });
      }
    }
  }, [
    // Analyse.Brand,
    // Analyse.KPI,
    // timeframeTrends,
    // DefaultStartDateTrends,
    // DefaultEndDateTrends,
    FetchCallTrends,
    // FromDatesAllTrends,
    // ToDatesAllTrends,
    ChartdataTrends,
    // ActiveFilters,
    // ActiveChart,
    IsAllEnabled,
    Reset,
  ]);

  // When Activefilter change : prepare chartdata for input of get api call

  useEffect(() => {
    console.log("resultt first time before ");
    if (changeFilter === false) {
      const chartdata = JSON.parse(localStorage.getItem("Chartdata"));
      console.log("resultt first time ", chartdata);

      setChartdata(chartdata);
      setChartdataTrends(chartdata);
      setChartdataComp1(chartdata);
      setChartdataComp2(chartdata);

      setFetchCall(true);
      setSelectedCheckboxes([]);
      setSelectedCheckboxesC([]);
      setSelectedCheckboxesC1([]);
      setSelectedCheckboxesC2([]);
      setSelectedCheckboxesH1([]);
      setSelectedCheckboxesH2([]);
      setFetchCallTrends(true);
      setFetchCallComp1(true);
      setFetchCallComp2(true);
    }
  }, [Analyse.Brand, ActiveChart, Analyse.KPI]);

  useEffect(() => {
    setChangeFilter(false);
  }, [ActiveChart]);

  //HEIRARCHY FILTERS
  const AddHeirarchyFilter = (index) => {
    const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));
    let filter = AllFilters[index];

    console.log("Add H");
    // Assuming you want to select the first filter by default as in AddCompFilter
    // filter.Selected = [filter?.filters[0]];

    ActiveFiltersTemp.push(filter);
    setActiveFilters(ActiveFiltersTemp);
    setFilterTabShow(true);

    // Update the localStorage with the new filter data
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: ActiveFiltersTemp,
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));
    updateChartData();
  };

  const RemoveHeirarchyFilter = (item) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(ActiveFilters));
    for (let i = 0; i < ActiveFiltersCompTemp.length; i++) {
      if (ActiveFiltersCompTemp[i].name === item.name) {
        ActiveFiltersCompTemp.splice(i, 1);
        setActiveFilters(ActiveFiltersCompTemp);
      }
    }
    setFilterTabShow(true);

    //Set analytical filters data in localstorage
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: ActiveFiltersCompTemp,
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));
    updateChartData();
  };

  const IsInAllHeirarchyFilters = (item) => {
    for (let i = 0; i < ActiveFilters.length; i++) {
      if (ActiveFilters[i].name === item.name) {
        return true;
      }
    }
    return false;
  };

  //CHANNEL FILTERS
  const AddCompFilter = (index) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(CompActiveFilters));
    let filter = CompAllFilters[index];
    filter.Selected = [filter.filters[0]];

    ActiveFiltersCompTemp.push(filter);
    setCompActiveFilters(ActiveFiltersCompTemp);
    setFilterTabShow(true);

    //Set campaign filter data in localstorage
    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: ActiveFiltersCompTemp,
    };

    localStorage.setItem(
      "CampaignAnalyticFilter",
      JSON.stringify(CampaignAnalyticFilter)
    );
    updateChartData([], [], true);
  };

  const RemoveCompFilter = (item) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(CompActiveFilters));
    for (let i = 0; i < ActiveFiltersCompTemp.length; i++) {
      if (ActiveFiltersCompTemp[i].name === item.name) {
        ActiveFiltersCompTemp.splice(i, 1);
        setCompActiveFilters(ActiveFiltersCompTemp);
      }
    }
    setFilterTabShow(true);

    //Set campaign filter data in localstorage
    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: ActiveFiltersCompTemp,
    };

    localStorage.setItem(
      "CampaignAnalyticFilter",
      JSON.stringify(CampaignAnalyticFilter)
    );
    updateChartData();
  };

  const IsInAllCompFilters = (item) => {
    for (let i = 0; i < CompActiveFilters.length; i++) {
      if (CompActiveFilters[i].name === item.name) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const handleInitailLevel = (level) => {
      const keysArrayOfChartData = Object.keys(
        ChartdataForHierarchyFilters()[0]
      );
      const ChartdataTemp = structuredClone(ChartdataForHierarchyFilters()[0]);

      console.log(
        "DrillDown ",
        ChartdataForHierarchyFilters(),
        " ",
        ChartdataForHierarchyFilters()
      );
      const result = keysArrayOfChartData.map(() => []);

      let SkipData = JSON.parse(
        localStorage.getItem("StoreAnalyticFilter")
      ).Fixed;

      console.log("SkipData.length initialLevel", SkipData.length);

      // const len=SkipData.length-InitialLevel-1;
      // Assign Selection to Skipdata
      // for(let i=0;i<len;i++){

      SkipData.pop(); // Removes the first element

      // }
      // Split each item in NotAllow by "-" and push the resulting arrays into result
      NotAllow.forEach((item) => {
        console.log("item ", item);
        const splitArray = item.split("-");
        result.forEach((res, index) => {
          // For each result array, push the corresponding slice of splitArray
          if (index <= splitArray.length - 1) {
            res.push(splitArray.slice(0, index + 1).join("-"));
          }
        });
      });

      // Create the payload array, filtering out duplicates
      let payloadArray = result.map((item) => {
        return [...new Set(item)]; // Remove duplicates
      });

      console.log(
        "Payload Array after nonclicklist:",
        payloadArray.slice(0, payloadArray.length - 1)
      );

      SkipData?.forEach((skipItem) => {
        const skipId = skipItem.id;

        // Check if ChartdataTemp includes the skipId as a key
        if (ChartdataTemp.hasOwnProperty(skipId)) {
          // Make the selection array same as the filter array
          ChartdataTemp[skipId].selection = ChartdataTemp[skipId].Filter;
        }
      });

      payloadArray = payloadArray.slice(0, payloadArray.length - 1);
      let SkipLength = SkipData?.length;

      // Step 2: Update `ChartdataTemp` with `payloadArray` after handling `SkipData`
      keysArrayOfChartData.slice(0, level).forEach((key, index) => {
        if (!SkipData?.some((skipItem) => skipItem.id === key)) {
          if (ChartdataTemp.hasOwnProperty(key)) {
            // Set the selection to the corresponding payloadArray values
            console.log("nonclicklist ", key, "   ", payloadArray[index]);
            ChartdataTemp[key].selection =
              payloadArray[index - SkipLength] || [];
          }
        }
      });

      console.log(
        "ChartdataTemp nonclicklist initialLevel",
        ChartdataTemp,
        "    ",
        Chartdata,
        " ",
        level
      );
      // setFetchCall(true);

      if (ActiveChart === "Measurement") {
        setChartdata(ChartdataTemp);
        console.log(
          "Activechart Chartdata ",
          ActiveChart,
          "    ",
          ChartdataTemp
        );
      } else if (ActiveChart === "Comparison") {
        setChartdataComp1(ChartdataTemp);
        setChartdataComp2(ChartdataTemp);
      } else {
        console.log("handleInitailLevel Calls ", ChartdataTemp);
        setChartdataTrends(ChartdataTemp);
      }

      // returnSetChartdata()[0](ChartdataTemp);
      // setChartdata(ChartdataTemp);
      // setShowChat(true);
    };
    // console.log("handleInitailLevel Calls ",NotAllow, " ",level);
    if (level !== -1 && NotAllow.length > 0) {
      console.log("handleInitailLevel Calls");
      handleInitailLevel(level);
    }
  }, [NotAllow, Analyse.Brand, Analyse.KPI, ActiveChart]);

  //  prepare BreadCrumbs....for Measurement, Comparison, and Trend Chart..................................
  useEffect(() => {
    if (ActiveChart === "Measurement") {
      if (JSON.stringify(Chartdata) !== "{}") {
        const cloneOfChartdata = structuredClone(Chartdata);
        const allKeysAlreadyInChartdata = Object.keys(cloneOfChartdata);

        // Retrieve SkipData from local storage
        let SkipData = JSON.parse(
          localStorage.getItem("StoreAnalyticFilter")
        ).Fixed;
        SkipData.pop();

        const skipIds = SkipData.map((item) => item.id);

        // Prepare a list to store lengths of valid breadcrumb selections
        const listToStoreLength = [];

        for (const key in cloneOfChartdata) {
          const list = cloneOfChartdata[key]["selection"];
          console.log(
            "allKeysAlreadyInChartdata list ",
            list,
            "    ",
            !skipIds.includes(key),
            "  ",
            cloneOfChartdata[key]
          );
          // Only process if the list length is greater than 0 and the key is not in skipIds
          if (list.length > 0 && !skipIds.includes(key)) {
            for (const name of list) {
              const arr = name.split("-");
              listToStoreLength.push(arr.length);
              console.log("allKeysAlreadyInChartdata arr", arr);
            }
          }
        }

        let maxNumber = Math.max(...listToStoreLength);

        // If listToStoreLength is empty, set maxNumber to 0
        if (listToStoreLength.length === 0) {
          maxNumber = 0;
        }

        // Set the breadcrumbs based on the maximum length
        console.log(
          "allKeysAlreadyInChartdata ",
          allKeysAlreadyInChartdata,
          "  ",
          maxNumber,
          " "
        );
        setBreadCrumbs(
          allKeysAlreadyInChartdata.slice(
            SkipData?.length,
            maxNumber + 1 + SkipData?.length
          )
        );
      }
    } else if (ActiveChart === "Comparison") {
      // Clone the comparison chart data
      const cloneOfChartdataComp1 = structuredClone(ChartdataComp1);
      const cloneOfChartdataComp2 = structuredClone(ChartdataComp2);

      // Get all keys from the cloned data
      const allKeysAlreadyInChartdataComp1 = Object.keys(cloneOfChartdataComp1);
      const allKeysAlreadyInChartdataComp2 = Object.keys(cloneOfChartdataComp2);

      // Retrieve SkipData from local storage
      let SkipData = JSON.parse(
        localStorage.getItem("StoreAnalyticFilter")
      ).Fixed;
      SkipData.pop(); // Remove the last element
      const skipIds = SkipData.map((item) => item.id);

      // Prepare lists to store lengths of valid breadcrumb selections
      const listToStoreLengthComp1 = [];
      const listToStoreLengthComp2 = [];

      // Process ChartdataComp1
      for (const key in cloneOfChartdataComp1) {
        const list = cloneOfChartdataComp1[key]["selection"];
        if (list.length > 0 && !skipIds.includes(key)) {
          for (const name of list) {
            const arr = name.split("-");
            listToStoreLengthComp1.push(arr.length);
          }
        }
      }

      // Process ChartdataComp2
      for (const key in cloneOfChartdataComp2) {
        const list = cloneOfChartdataComp2[key]["selection"];
        if (list.length > 0 && !skipIds.includes(key)) {
          for (const name of list) {
            const arr = name.split("-");
            listToStoreLengthComp2.push(arr.length);
          }
        }
      }

      // Determine the maximum lengths
      let maxNumberComp1 = Math.max(...listToStoreLengthComp1);
      let maxNumberComp2 = Math.max(...listToStoreLengthComp2);

      // Handle cases where no valid selections were found
      if (listToStoreLengthComp1.length === 0) {
        maxNumberComp1 = 0;
      }
      if (listToStoreLengthComp2.length === 0) {
        maxNumberComp2 = 0;
      }

      // Set breadcrumbs based on the maximum length for each comparison chart
      setBreadCrumbsComp1(
        allKeysAlreadyInChartdataComp1.slice(
          SkipData?.length,
          maxNumberComp1 + 1 + SkipData?.length
        )
      );
      setBreadCrumbsComp2(
        allKeysAlreadyInChartdataComp2.slice(
          SkipData?.length,
          maxNumberComp2 + 1 + SkipData?.length
        )
      );
    } else {
      if (JSON.stringify(ChartdataTrends) !== "{}") {
        const cloneOfChartdata = structuredClone(ChartdataTrends);
        const allKeysAlreadyInChartdata = Object.keys(cloneOfChartdata);

        // Retrieve SkipData from local storage
        let SkipData = JSON.parse(
          localStorage.getItem("StoreAnalyticFilter")
        ).Fixed;
        SkipData.pop();

        const skipIds = SkipData.map((item) => item.id);

        // Prepare a list to store lengths of valid breadcrumb selections
        const listToStoreLength = [];

        for (const key in cloneOfChartdata) {
          const list = cloneOfChartdata[key]["selection"];
          console.log(
            "allKeysAlreadyInChartdata list ",
            list,
            "    ",
            !skipIds.includes(key),
            "  ",
            cloneOfChartdata[key]
          );
          // Only process if the list length is greater than 0 and the key is not in skipIds
          if (list.length > 0 && !skipIds.includes(key)) {
            for (const name of list) {
              const arr = name.split("-");
              listToStoreLength.push(arr.length);
              console.log("allKeysAlreadyInChartdata arr", arr);
            }
          }
        }

        let maxNumber = Math.max(...listToStoreLength);

        // If listToStoreLength is empty, set maxNumber to 0
        if (listToStoreLength.length === 0) {
          maxNumber = 0;
        }

        // Set the breadcrumbs based on the maximum length
        console.log(
          "allKeysAlreadyInChartdata ",
          allKeysAlreadyInChartdata,
          "  ",
          maxNumber,
          " "
        );
        setBreadCrumbsTrends(
          allKeysAlreadyInChartdata.slice(
            SkipData?.length,
            maxNumber + 1 + SkipData?.length
          )
        );
      }
    }
  }, [ActiveChart, Chartdata, ChartdataComp1, ChartdataComp2, ChartdataTrends]);

  // Drill up and drill down of measurement chart  ====================================================

  // for point click
  const DrillDown = (category) => {
    console.log("clicked category point click: ", category);

    const splitNameList = category.split("-");
    const Length = splitNameList.length;

    const resultArray = []; // Initialize an empty array to store the concatenated strings

    let currentString = ""; // To accumulate the concatenated parts

    // Iterate over each part in splitNameList
    splitNameList.forEach((part, index) => {
      // For the first iteration, just set the currentString to the first part
      if (index === 0) {
        currentString = part;
      } else {
        // For subsequent iterations, append the next part with a hyphen
        currentString += `-${part}`;
      }
      // Push the progressively concatenated string to the resultArray
      resultArray.push(currentString);
    });

    console.log("Length point click: ", Length);

    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;

    SkipData.pop();

    let SkipLength = SkipData?.length;
    console.log("SKIPDATA ", SkipData, SkipLength);

    const ChartdataTemp = structuredClone(Chartdata);
    const allKeysAlreadyInChartdata = Object.keys(Chartdata);
    // console.log("allKeysAlreadyInChartdatra: ", allKeysAlreadyInChartdata);

    const keyToAddSelectionFilterInChartdata =
      allKeysAlreadyInChartdata[Length - 1 + SkipLength];

    console.log(
      "keyToAddSelectionFilterInChartdata: ",
      keyToAddSelectionFilterInChartdata
    );

    let cloneOfChartdata = structuredClone(Chartdata);

    SkipData?.forEach((skipItem) => {
      const skipId = skipItem.id;
      console.log("skipId ", skipId);
      // Check if ChartdataTemp includes the skipId as a key
      if (cloneOfChartdata.hasOwnProperty(skipId)) {
        // Make the selection array same as the filter array
        cloneOfChartdata[skipId].selection = cloneOfChartdata[skipId].Filter;
      }
    });

    console.log(
      "point clicked ",
      Length,
      " ",
      SkipLength,
      "   ",
      splitNameList,
      " ",
      keyToAddSelectionFilterInChartdata
    );
    let index = 0;
    for (let i = 0; i < Length - 1 + SkipLength; i++) {
      const key = allKeysAlreadyInChartdata[i];
      console.log("key point click ", key);
      if (cloneOfChartdata[key].Filter?.length > 0) {
        cloneOfChartdata[key].selection = cloneOfChartdata[key].Filter;
      } else cloneOfChartdata[key].selection.push(resultArray[SkipLength + i]);
    }

    if (cloneOfChartdata.hasOwnProperty(keyToAddSelectionFilterInChartdata)) {
      cloneOfChartdata[keyToAddSelectionFilterInChartdata].selection.push(
        category
      );
    }

    setChartdata(cloneOfChartdata);
    setFetchCall(true);
    // setClickedCategory(Category);
    setIsAllEnabledDownload(0);
    setLevel(-1);
  };

  const displayNames = JSON.parse(localStorage.getItem("displayNames"));

  const convertLabelToId = (label, displayNames) => {
    // Split the label by hyphens
    const labelParts = label.split("-");

    // Convert each part into its corresponding id
    const idParts = labelParts.map((part) => {
      // Find the key where the value matches the part

      const foundId = Object.keys(displayNames).find(
        (id) => displayNames[id] === part
      );
      return foundId || part; // Use the part itself if no matching id is found
    });

    // Join the id parts with hyphens
    const resultId = idParts.join("-");

    return resultId;
  };

  // for label click
  const DrillUp = (category) => {
    console.log("Activechart Chartdata  DrillUP", Chartdata);
    // console.log("category: ", category.value);
    var span = document.createElement("span");
    span.innerHTML = category.value;
    const label = span.firstChild.getAttribute("id");

    let label_id = label;
    console.log("label: before", label_id);

    const convertedId = label;

    console.log("label: ", convertedId);
    const ChartdataTemp = structuredClone(Chartdata);
    let parentClear = false;
    // for (const key in ChartdataTemp) {
    //   // Remove the label from the selection array if it is present
    //   ChartdataTemp[key].selection = ChartdataTemp[key].selection.filter(
    //     (value) => value !== convertedId
    //   );
    //   if (parentClear) {
    //     ChartdataTemp[key].selection = [];
    //   }
    //   if (ChartdataTemp[key].selection.length === 0) {
    //     parentClear = true;
    //   }
    // }
    for (const key in ChartdataTemp) {
      // Remove the label from the selection array if it is present
      ChartdataTemp[key].selection = ChartdataTemp[key].selection.filter(
        (value) => !value.startsWith(convertedId)
      );
      console.log(
        "label click ",
        key,
        "  ",
        ChartdataTemp[key].selection,
        "    ",
        ChartdataTemp
      );
    }

    console.log("label: chartDatatemp ", ChartdataTemp);

    setChartdata(ChartdataTemp);
    setFetchCall(true);
    setIsAllEnabledDownload(0);
    setLevel(-1);
  };

  // for down arrow
  const DrillDownAll = () => {
    console.log("DRILLDOWN CHARTDATA", Chartdata);
    // Get the keys in Chartdata
    const keysAlreadyInChartData = Object.keys(Chartdata);

    // Clone Chartdata to avoid mutating the original object
    const ChartdataTemp = structuredClone(Chartdata);

    // Retrieve SkipData from local storage
    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;

    SkipData.pop();

    let SkipLength = SkipData?.length;
    // Extract the IDs from SkipData
    const skipIds = SkipData?.map((item) => item.id);

    // Create empty arrays based on the number of keys in Chartdata
    const result = keysAlreadyInChartData.map(() => []);

    // Process the NotAllow array
    NotAllow.forEach((item) => {
      const splitArray = item.split("-");
      result.forEach((res, index) => {
        // For each result array, push the corresponding slice of splitArray
        res.push(splitArray.slice(0, index + 1).join("-"));
      });
    });

    // Create the payload array, filtering out duplicates
    let payloadArray = result.map((item) => {
      return [...new Set(item)]; // Remove duplicates
    });

    payloadArray = payloadArray.slice(0, payloadArray.length - 1);

    // Find the next element that is not in SkipData and has no selection or shorter selection
    let nextKey = null;
    let nextIndex = -1;
    SkipData?.forEach((skipItem) => {
      const skipId = skipItem?.id;

      // Check if ChartdataTemp includes the skipId as a key
      if (ChartdataTemp.hasOwnProperty(skipId)) {
        // Make the selection array same as the filter array
        ChartdataTemp[skipId].selection = ChartdataTemp[skipId].Filter;
      }
    });

    for (let i = 0; i < keysAlreadyInChartData.length - 1; i++) {
      const key = keysAlreadyInChartData[i];
      if (
        ChartdataTemp.hasOwnProperty(key) &&
        !skipIds?.includes(key) &&
        ChartdataTemp[key].selection.length === 0
      ) {
        nextKey = key;
        nextIndex = i;
        break;
      }
    }

    // If a nextKey is found and it's not in SkipData, set its selection with processedNotAllow data
    if (nextKey && ChartdataTemp.hasOwnProperty(nextKey)) {
      ChartdataTemp[nextKey].selection = payloadArray[nextIndex - SkipLength];
    }

    console.log("DrillDownAll - Updated ChartdataTemp:", ChartdataTemp);

    // Update the state with the modified ChartdataTemp
    setChartdata(ChartdataTemp);
    setFetchCall(true);
    setIsAllEnabledDownload(0);
    setLevel(-1);
  };

  // for up arrow
  const DrillUpAll = () => {
    // Get the keys in Chartdata
    const keysAlreadyInChartData = Object.keys(Chartdata);

    // Clone Chartdata to avoid mutating the original object
    const ChartdataTemp = structuredClone(Chartdata);

    // Retrieve SkipData from local storage
    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;
    SkipData.pop();
    // Extract the IDs from SkipData
    const skipIds = SkipData?.map((item) => item.id);

    // Identify the last key in ChartdataTemp that has a non-empty selection and is not in SkipData
    const lastKey = keysAlreadyInChartData.reverse().find((key) => {
      return (
        ChartdataTemp[key].selection &&
        ChartdataTemp[key].selection.length > 0 &&
        !skipIds?.includes(key)
      );
    });

    // If a lastKey is found and it's not in SkipData, clear its selection
    if (lastKey && ChartdataTemp.hasOwnProperty(lastKey)) {
      ChartdataTemp[lastKey].selection = [];
    }

    console.log("DrillUpAll - Updated ChartdataTemp:", ChartdataTemp);

    // Update the state with the modified ChartdataTemp
    setChartdata(ChartdataTemp);
    setFetchCall(true);
    setIsAllEnabledDownload(0);
    setLevel(-1);
  };

  // tree button
  const DrillAll = () => {
    console.log("nonclicklist ", NotAllow);
    const keysArrayOfChartData = Object.keys(Chartdata);
    const ChartdataTemp = structuredClone(Chartdata);

    // Create an array of empty arrays for each key in Chartdata
    const result = keysArrayOfChartData.map(() => []);

    // Split each item in NotAllow by "-" and push the resulting arrays into result
    NotAllow.forEach((item) => {
      const splitArray = item.split("-");
      result.forEach((res, index) => {
        // For each result array, push the corresponding slice of splitArray
        res.push(splitArray.slice(0, index + 1).join("-"));
      });
    });

    // Create the payload array, filtering out duplicates
    let payloadArray = result.map((item) => {
      return [...new Set(item)]; // Remove duplicates
    });

    console.log(
      "Payload Array after nonclicklist:",
      payloadArray.slice(0, payloadArray.length - 1)
    );

    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;

    // Assign Selection to Skipdata
    SkipData.pop();
    // Step 1: Update `ChartdataTemp` for ids in `SkipData`
    // Loop through the sliced SkipData
    SkipData?.forEach((skipItem) => {
      const skipId = skipItem.id;

      // Check if ChartdataTemp includes the skipId as a key
      if (ChartdataTemp.hasOwnProperty(skipId)) {
        // Make the selection array same as the filter array
        ChartdataTemp[skipId].selection = ChartdataTemp[skipId].Filter;
      }
    });

    payloadArray = payloadArray.slice(0, payloadArray.length - 1);
    let SkipLength = SkipData?.length;

    // Step 2: Update `ChartdataTemp` with `payloadArray` after handling `SkipData`
    keysArrayOfChartData.slice(0, -1).forEach((key, index) => {
      if (!SkipData?.some((skipItem) => skipItem.id === key)) {
        if (ChartdataTemp.hasOwnProperty(key)) {
          // Set the selection to the corresponding payloadArray values
          console.log("nonclicklist ", key, "   ", payloadArray[index]);
          ChartdataTemp[key].selection = payloadArray[index - SkipLength] || [];
        }
      }
    });

    console.log("ChartdataTemp nonclicklist", ChartdataTemp);

    setIsAllEnabled(1);
    setFetchCall(true);
    setChartdata(ChartdataTemp);
    setLevel(-1);
  };

  // for reset button
  const ResetAll = () => {
    let ChartdataTemp = {};
    for (const filter in Chartdata) {
      ChartdataTemp[filter] = Chartdata[filter];
      ChartdataTemp[filter].selection = [];
    }
    setLevel(DefaultLevel);
    setReset(1);
    setChartdata(ChartdataTemp);
    setFetchCall(true);
    setIsAllEnabledDownload(0);
  };

  // code end for measurement...........................................

  // Drill up and drill down of ccomparison chart 1 ====================================================

  // for point click
  const DrillDownComp1 = (category) => {
    console.log("clicked category point click: ", category);

    const splitNameList = category.split("-");
    const Length = splitNameList.length;

    const resultArray = []; // Initialize an empty array to store the concatenated strings

    let currentString = ""; // To accumulate the concatenated parts

    // Iterate over each part in splitNameList
    splitNameList.forEach((part, index) => {
      // For the first iteration, just set the currentString to the first part
      if (index === 0) {
        currentString = part;
      } else {
        // For subsequent iterations, append the next part with a hyphen
        currentString += `-${part}`;
      }
      // Push the progressively concatenated string to the resultArray
      resultArray.push(currentString);
    });

    console.log("Length point click: ", Length);

    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;

    SkipData.pop();

    let SkipLength = SkipData?.length;
    console.log("SKIPDATA ", SkipData, SkipLength);

    const ChartdataTemp = structuredClone(ChartdataComp1);
    const allKeysAlreadyInChartdata = Object.keys(ChartdataComp1);
    // console.log("allKeysAlreadyInChartdatra: ", allKeysAlreadyInChartdata);

    const keyToAddSelectionFilterInChartdata =
      allKeysAlreadyInChartdata[Length - 1 + SkipLength];

    console.log(
      "keyToAddSelectionFilterInChartdata: ",
      keyToAddSelectionFilterInChartdata
    );

    let cloneOfChartdata = structuredClone(ChartdataComp1);

    SkipData?.forEach((skipItem) => {
      const skipId = skipItem.id;
      console.log("skipId ", skipId);
      // Check if ChartdataTemp includes the skipId as a key
      if (cloneOfChartdata.hasOwnProperty(skipId)) {
        // Make the selection array same as the filter array
        cloneOfChartdata[skipId].selection = cloneOfChartdata[skipId].Filter;
      }
    });

    console.log(
      "point clicked ",
      Length,
      " ",
      SkipLength,
      "   ",
      splitNameList,
      " ",
      keyToAddSelectionFilterInChartdata
    );
    let index = 0;
    for (let i = 0; i < Length - 1 + SkipLength; i++) {
      const key = allKeysAlreadyInChartdata[i];
      console.log("key point click ", key);
      if (cloneOfChartdata[key].Filter?.length > 0) {
        cloneOfChartdata[key].selection = cloneOfChartdata[key].Filter;
      } else cloneOfChartdata[key].selection.push(resultArray[SkipLength + i]);
    }

    if (cloneOfChartdata.hasOwnProperty(keyToAddSelectionFilterInChartdata)) {
      cloneOfChartdata[keyToAddSelectionFilterInChartdata].selection.push(
        category
      );
    }

    setChartdataComp1(cloneOfChartdata);
    setFetchCallComp1(true);
    // setClickedCategory(Category);
    setIsAllEnabledDownload(0);
    setLevel(-1);
  };
  // for label click
  const DrillUpComp1 = (category) => {
    // console.log("category: ", category.value);
    var span = document.createElement("span");
    span.innerHTML = category.value;
    const label = span.firstChild.getAttribute("id");

    let label_id = label;
    console.log("label: before", label_id);

    const convertedId = label;

    console.log("label: ", convertedId);
    const ChartdataTemp = structuredClone(ChartdataComp1);
    let parentClear = false;
    for (const key in ChartdataTemp) {
      // Remove the label from the selection array if it is present
      ChartdataTemp[key].selection = ChartdataTemp[key].selection.filter(
        (value) => value !== convertedId
      );
      if (parentClear) {
        ChartdataTemp[key].selection = [];
      }
      if (ChartdataTemp[key].selection.length === 0) {
        parentClear = true;
      }
    }

    console.log("label: chartDatatemp ", ChartdataTemp);

    setChartdataComp1(ChartdataTemp);
    setFetchCallComp1(true);
    setIsAllEnabledDownload(0);
    setLevel(-1);
  };

  // for down arrow
  const DrillDownAll1 = () => {
    // Get the keys in Chartdata
    const keysAlreadyInChartData = Object.keys(ChartdataComp1);

    // Clone Chartdata to avoid mutating the original object
    const ChartdataTemp = structuredClone(ChartdataComp1);

    // Retrieve SkipData from local storage
    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;

    SkipData.pop();

    let SkipLength = SkipData?.length;
    // Extract the IDs from SkipData
    const skipIds = SkipData?.map((item) => item.id);

    // Create empty arrays based on the number of keys in Chartdata
    const result = keysAlreadyInChartData.map(() => []);

    // Process the NotAllow array
    NotAllow.forEach((item) => {
      const splitArray = item.split("-");
      result.forEach((res, index) => {
        // For each result array, push the corresponding slice of splitArray
        res.push(splitArray.slice(0, index + 1).join("-"));
      });
    });

    // Create the payload array, filtering out duplicates
    let payloadArray = result.map((item) => {
      return [...new Set(item)]; // Remove duplicates
    });

    payloadArray = payloadArray.slice(0, payloadArray.length - 1);

    // Find the next element that is not in SkipData and has no selection or shorter selection
    let nextKey = null;
    let nextIndex = -1;
    SkipData?.forEach((skipItem) => {
      const skipId = skipItem?.id;

      // Check if ChartdataTemp includes the skipId as a key
      if (ChartdataTemp.hasOwnProperty(skipId)) {
        // Make the selection array same as the filter array
        ChartdataTemp[skipId].selection = ChartdataTemp[skipId].Filter;
      }
    });

    for (let i = 0; i < keysAlreadyInChartData.length - 1; i++) {
      const key = keysAlreadyInChartData[i];
      if (
        ChartdataTemp.hasOwnProperty(key) &&
        !skipIds?.includes(key) &&
        ChartdataTemp[key].selection.length === 0
      ) {
        nextKey = key;
        nextIndex = i;
        break;
      }
    }

    // If a nextKey is found and it's not in SkipData, set its selection with processedNotAllow data
    if (nextKey && ChartdataTemp.hasOwnProperty(nextKey)) {
      ChartdataTemp[nextKey].selection = payloadArray[nextIndex - SkipLength];
    }

    console.log("DrillDownAll - Updated ChartdataTemp:", ChartdataTemp);

    // Update the state with the modified ChartdataTemp
    setChartdataComp1(ChartdataTemp);
    setFetchCallComp1(true);
    setIsAllEnabledDownload(0);
    setLevel(-1);
  };

  // for up arrow
  const DrillUpAll1 = () => {
    // Get the keys in Chartdata
    const keysAlreadyInChartData = Object.keys(ChartdataComp1);

    // Clone Chartdata to avoid mutating the original object
    const ChartdataTemp = structuredClone(ChartdataComp1);

    // Retrieve SkipData from local storage
    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;
    SkipData.pop();
    // Extract the IDs from SkipData
    const skipIds = SkipData?.map((item) => item.id);

    // Identify the last key in ChartdataTemp that has a non-empty selection and is not in SkipData
    const lastKey = keysAlreadyInChartData.reverse().find((key) => {
      return (
        ChartdataTemp[key].selection &&
        ChartdataTemp[key].selection.length > 0 &&
        !skipIds?.includes(key)
      );
    });

    // If a lastKey is found and it's not in SkipData, clear its selection
    if (lastKey && ChartdataTemp.hasOwnProperty(lastKey)) {
      ChartdataTemp[lastKey].selection = [];
    }

    console.log("DrillUpAll - Updated ChartdataTemp:", ChartdataTemp);

    // Update the state with the modified ChartdataTemp
    setChartdataComp1(ChartdataTemp);
    setFetchCallComp1(true);
    setIsAllEnabledDownload(0);
    setLevel(-1);
  };

  // tree button
  const DrillAll1 = () => {
    console.log("nonclicklist ", NotAllow);
    const keysArrayOfChartData = Object.keys(ChartdataComp1);
    const ChartdataTemp = structuredClone(ChartdataComp1);

    // Create an array of empty arrays for each key in Chartdata
    const result = keysArrayOfChartData.map(() => []);

    // Split each item in NotAllow by "-" and push the resulting arrays into result
    NotAllow.forEach((item) => {
      const splitArray = item.split("-");
      result.forEach((res, index) => {
        // For each result array, push the corresponding slice of splitArray
        res.push(splitArray.slice(0, index + 1).join("-"));
      });
    });

    // Create the payload array, filtering out duplicates
    let payloadArray = result.map((item) => {
      return [...new Set(item)]; // Remove duplicates
    });

    console.log(
      "Payload Array after nonclicklist:",
      payloadArray.slice(0, payloadArray.length - 1)
    );

    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;

    // Assign Selection to Skipdata
    SkipData.pop();
    // Step 1: Update `ChartdataTemp` for ids in `SkipData`
    // Loop through the sliced SkipData
    SkipData?.forEach((skipItem) => {
      const skipId = skipItem.id;

      // Check if ChartdataTemp includes the skipId as a key
      if (ChartdataTemp.hasOwnProperty(skipId)) {
        // Make the selection array same as the filter array
        ChartdataTemp[skipId].selection = ChartdataTemp[skipId].Filter;
      }
    });

    payloadArray = payloadArray.slice(0, payloadArray.length - 1);
    let SkipLength = SkipData?.length;

    // Step 2: Update `ChartdataTemp` with `payloadArray` after handling `SkipData`
    keysArrayOfChartData.slice(0, -1).forEach((key, index) => {
      if (!SkipData?.some((skipItem) => skipItem.id === key)) {
        if (ChartdataTemp.hasOwnProperty(key)) {
          // Set the selection to the corresponding payloadArray values
          console.log("nonclicklist ", key, "   ", payloadArray[index]);
          ChartdataTemp[key].selection = payloadArray[index - SkipLength] || [];
        }
      }
    });

    console.log("ChartdataTemp nonclicklist", ChartdataTemp);

    setIsAllEnabled(1);
    setFetchCallComp1(true);
    setChartdataComp1(ChartdataTemp);
    setLevel(-1);
  };

  // for reset button
  const ResetAll1 = () => {
    let ChartdataTemp = {};
    for (const filter in ChartdataComp1) {
      ChartdataTemp[filter] = ChartdataComp1[filter];
      ChartdataTemp[filter].selection = [];
    }
    setLevel(DefaultLevel);
    setReset(1);
    setChartdataComp1(ChartdataTemp);
    setFetchCallComp1(true);
    setIsAllEnabledDownload(0);
  };

  /// Drill up and drill down of ccomparison chart 2  ===============================================
  // for point click

  // for point click
  const DrillDownComp2 = (category) => {
    console.log("clicked category point click: ", category);

    const splitNameList = category.split("-");
    const Length = splitNameList.length;

    const resultArray = []; // Initialize an empty array to store the concatenated strings

    let currentString = ""; // To accumulate the concatenated parts

    // Iterate over each part in splitNameList
    splitNameList.forEach((part, index) => {
      // For the first iteration, just set the currentString to the first part
      if (index === 0) {
        currentString = part;
      } else {
        // For subsequent iterations, append the next part with a hyphen
        currentString += `-${part}`;
      }
      // Push the progressively concatenated string to the resultArray
      resultArray.push(currentString);
    });

    console.log("Length point click: ", Length);

    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;

    SkipData.pop();

    let SkipLength = SkipData?.length;
    console.log("SKIPDATA ", SkipData, SkipLength);

    const ChartdataTemp = structuredClone(ChartdataComp2);
    const allKeysAlreadyInChartdata = Object.keys(ChartdataComp2);
    // console.log("allKeysAlreadyInChartdatra: ", allKeysAlreadyInChartdata);

    const keyToAddSelectionFilterInChartdata =
      allKeysAlreadyInChartdata[Length - 1 + SkipLength];

    console.log(
      "keyToAddSelectionFilterInChartdata: ",
      keyToAddSelectionFilterInChartdata
    );

    let cloneOfChartdata = structuredClone(ChartdataComp2);

    SkipData?.forEach((skipItem) => {
      const skipId = skipItem.id;
      console.log("skipId ", skipId);
      // Check if ChartdataTemp includes the skipId as a key
      if (cloneOfChartdata.hasOwnProperty(skipId)) {
        // Make the selection array same as the filter array
        cloneOfChartdata[skipId].selection = cloneOfChartdata[skipId].Filter;
      }
    });

    console.log(
      "point clicked ",
      Length,
      " ",
      SkipLength,
      "   ",
      splitNameList,
      " ",
      keyToAddSelectionFilterInChartdata
    );
    let index = 0;
    for (let i = 0; i < Length - 1 + SkipLength; i++) {
      const key = allKeysAlreadyInChartdata[i];
      console.log("key point click ", key);
      if (cloneOfChartdata[key].Filter?.length > 0) {
        cloneOfChartdata[key].selection = cloneOfChartdata[key].Filter;
      } else cloneOfChartdata[key].selection.push(resultArray[SkipLength + i]);
    }

    if (cloneOfChartdata.hasOwnProperty(keyToAddSelectionFilterInChartdata)) {
      cloneOfChartdata[keyToAddSelectionFilterInChartdata].selection.push(
        category
      );
    }

    setChartdataComp2(cloneOfChartdata);
    setFetchCallComp2(true);
    // setClickedCategory(Category);
    setIsAllEnabledDownload(0);
    setLevel(-1);
  };

  // for label click
  const DrillUpComp2 = (category) => {
    // console.log("category: ", category.value);
    var span = document.createElement("span");
    span.innerHTML = category.value;
    const label = span.firstChild.getAttribute("id");

    let label_id = label;
    console.log("label: before", label_id);

    const convertedId = label;

    console.log("label: ", convertedId);
    const ChartdataTemp = structuredClone(ChartdataComp2);
    let parentClear = false;
    for (const key in ChartdataTemp) {
      // Remove the label from the selection array if it is present
      ChartdataTemp[key].selection = ChartdataTemp[key].selection.filter(
        (value) => value !== convertedId
      );
      if (parentClear) {
        ChartdataTemp[key].selection = [];
      }
      if (ChartdataTemp[key].selection.length === 0) {
        parentClear = true;
      }
    }

    console.log("label: chartDatatemp ", ChartdataTemp);

    setChartdataComp2(ChartdataTemp);
    setFetchCallComp2(true);
    setIsAllEnabledDownload(0);
    setLevel(-1);
  };

  // for down arrow
  const DrillDownAll2 = () => {
    // Get the keys in Chartdata
    const keysAlreadyInChartData = Object.keys(ChartdataComp2);

    // Clone Chartdata to avoid mutating the original object
    const ChartdataTemp = structuredClone(ChartdataComp2);

    // Retrieve SkipData from local storage
    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;

    SkipData.pop();

    let SkipLength = SkipData?.length;
    // Extract the IDs from SkipData
    const skipIds = SkipData?.map((item) => item.id);

    // Create empty arrays based on the number of keys in Chartdata
    const result = keysAlreadyInChartData.map(() => []);

    // Process the NotAllow array
    NotAllow.forEach((item) => {
      const splitArray = item.split("-");
      result.forEach((res, index) => {
        // For each result array, push the corresponding slice of splitArray
        res.push(splitArray.slice(0, index + 1).join("-"));
      });
    });

    // Create the payload array, filtering out duplicates
    let payloadArray = result.map((item) => {
      return [...new Set(item)]; // Remove duplicates
    });

    payloadArray = payloadArray.slice(0, payloadArray.length - 1);

    // Find the next element that is not in SkipData and has no selection or shorter selection
    let nextKey = null;
    let nextIndex = -1;
    SkipData?.forEach((skipItem) => {
      const skipId = skipItem?.id;

      // Check if ChartdataTemp includes the skipId as a key
      if (ChartdataTemp.hasOwnProperty(skipId)) {
        // Make the selection array same as the filter array
        ChartdataTemp[skipId].selection = ChartdataTemp[skipId].Filter;
      }
    });

    for (let i = 0; i < keysAlreadyInChartData.length - 1; i++) {
      const key = keysAlreadyInChartData[i];
      if (
        ChartdataTemp.hasOwnProperty(key) &&
        !skipIds?.includes(key) &&
        ChartdataTemp[key].selection.length === 0
      ) {
        nextKey = key;
        nextIndex = i;
        break;
      }
    }

    // If a nextKey is found and it's not in SkipData, set its selection with processedNotAllow data
    if (nextKey && ChartdataTemp.hasOwnProperty(nextKey)) {
      ChartdataTemp[nextKey].selection = payloadArray[nextIndex - SkipLength];
    }

    console.log("DrillDownAll - Updated ChartdataTemp:", ChartdataTemp);

    // Update the state with the modified ChartdataTemp
    setChartdataComp2(ChartdataTemp);
    setFetchCallComp2(true);
    setIsAllEnabledDownload(0);
    setLevel(-1);
  };

  // for up arrow
  const DrillUpAll2 = () => {
    // Get the keys in Chartdata
    const keysAlreadyInChartData = Object.keys(ChartdataComp2);

    // Clone Chartdata to avoid mutating the original object
    const ChartdataTemp = structuredClone(ChartdataComp2);

    // Retrieve SkipData from local storage
    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;
    SkipData.pop();
    // Extract the IDs from SkipData
    const skipIds = SkipData?.map((item) => item.id);

    // Identify the last key in ChartdataTemp that has a non-empty selection and is not in SkipData
    const lastKey = keysAlreadyInChartData.reverse().find((key) => {
      return (
        ChartdataTemp[key].selection &&
        ChartdataTemp[key].selection.length > 0 &&
        !skipIds?.includes(key)
      );
    });

    // If a lastKey is found and it's not in SkipData, clear its selection
    if (lastKey && ChartdataTemp.hasOwnProperty(lastKey)) {
      ChartdataTemp[lastKey].selection = [];
    }

    console.log("DrillUpAll - Updated ChartdataTemp:", ChartdataTemp);

    // Update the state with the modified ChartdataTemp
    setChartdataComp2(ChartdataTemp);
    setFetchCallComp2(true);
    setIsAllEnabledDownload(0);
    setLevel(-1);
  };

  // tree button
  const DrillAll2 = () => {
    console.log("nonclicklist ", NotAllow);
    const keysArrayOfChartData = Object.keys(ChartdataComp2);
    const ChartdataTemp = structuredClone(ChartdataComp2);

    // Create an array of empty arrays for each key in Chartdata
    const result = keysArrayOfChartData.map(() => []);

    // Split each item in NotAllow by "-" and push the resulting arrays into result
    NotAllow.forEach((item) => {
      const splitArray = item.split("-");
      result.forEach((res, index) => {
        // For each result array, push the corresponding slice of splitArray
        res.push(splitArray.slice(0, index + 1).join("-"));
      });
    });

    // Create the payload array, filtering out duplicates
    let payloadArray = result.map((item) => {
      return [...new Set(item)]; // Remove duplicates
    });

    console.log(
      "Payload Array after nonclicklist:",
      payloadArray.slice(0, payloadArray.length - 1)
    );

    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;

    // Assign Selection to Skipdata
    SkipData.pop();
    // Step 1: Update `ChartdataTemp` for ids in `SkipData`
    // Loop through the sliced SkipData
    SkipData?.forEach((skipItem) => {
      const skipId = skipItem.id;

      // Check if ChartdataTemp includes the skipId as a key
      if (ChartdataTemp.hasOwnProperty(skipId)) {
        // Make the selection array same as the filter array
        ChartdataTemp[skipId].selection = ChartdataTemp[skipId].Filter;
      }
    });

    payloadArray = payloadArray.slice(0, payloadArray.length - 1);
    let SkipLength = SkipData?.length;

    // Step 2: Update `ChartdataTemp` with `payloadArray` after handling `SkipData`
    keysArrayOfChartData.slice(0, -1).forEach((key, index) => {
      if (!SkipData?.some((skipItem) => skipItem.id === key)) {
        if (ChartdataTemp.hasOwnProperty(key)) {
          // Set the selection to the corresponding payloadArray values
          console.log("nonclicklist ", key, "   ", payloadArray[index]);
          ChartdataTemp[key].selection = payloadArray[index - SkipLength] || [];
        }
      }
    });

    console.log("ChartdataTemp nonclicklist", ChartdataTemp);

    setIsAllEnabled(1);
    setFetchCallComp2(true);
    setChartdataComp2(ChartdataTemp);
    setLevel(-1);
  };

  // for reset button
  const ResetAll2 = () => {
    let ChartdataTemp = {};
    for (const filter in ChartdataComp2) {
      ChartdataTemp[filter] = ChartdataComp2[filter];
      ChartdataTemp[filter].selection = [];
    }
    setLevel(DefaultLevel);
    setReset(1);
    setChartdataComp2(ChartdataTemp);
    setFetchCallComp2(true);
    setIsAllEnabledDownload(0);
  };

  // =================================================================================================

  // for trends start................................................
  // ===============================================================================
  // for point click
  const DrillDownTrends = (Category) => {
    console.log(
      "clicked category point click: ",
      Category,
      "  ",
      ChartdataTrends
    );

    const splitNameList = Category.series.name.split("-");
    const Length = splitNameList.length;

    console.log("Length point click: ", Length);

    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;
    SkipData.pop();

    let SkipLength = SkipData?.length;
    console.log("SKIPDATA ", SkipData, SkipLength);
    let cloneOfChartdataTrends = structuredClone(ChartdataTrends);

    const ChartdataTrendsTemp = structuredClone(ChartdataTrends);
    const allKeysAlreadyInChartdata = Object.keys(ChartdataTrends);

    const keyToAddSelectionFilterInChartdata =
      allKeysAlreadyInChartdata[Length - 1 + SkipLength];

    console.log(
      "keyToAddSelectionFilterInChartdata: ",
      keyToAddSelectionFilterInChartdata
    );

    for (let i = 0; i < Length - 1 + SkipLength; i++) {
      const key = allKeysAlreadyInChartdata[i];
      if (cloneOfChartdataTrends[key].Filter.length > 0) {
        cloneOfChartdataTrends[key].selection =
          cloneOfChartdataTrends[key].Filter;
      }
    }

    SkipData?.forEach((skipItem) => {
      const skipId = skipItem.id;

      if (cloneOfChartdataTrends.hasOwnProperty(skipId)) {
        cloneOfChartdataTrends[skipId].selection =
          cloneOfChartdataTrends[skipId].Filter;
      }
    });

    if (
      cloneOfChartdataTrends.hasOwnProperty(keyToAddSelectionFilterInChartdata)
    ) {
      cloneOfChartdataTrends[keyToAddSelectionFilterInChartdata].selection.push(
        Category.series.name
      );
    }

    setChartdataTrends(cloneOfChartdataTrends);
    setFetchCallTrends(true);
    setIsAllEnabledDownload(0);
    setLevel(-1);
  };
  // For label click
  const DrillUpTrends = (category) => {
    var span = document.createElement("span");
    span.innerHTML = category.value;
    const label = span.firstChild.getAttribute("title");

    let label_id = label;
    console.log("label: before", label_id);

    const convertedId = convertLabelToId(label, displayNames);

    console.log("label: ", convertedId);
    const ChartdataTemp = structuredClone(ChartdataTrends);
    let parentClear = false;
    for (const key in ChartdataTemp) {
      // Remove the label from the selection array if it is present
      ChartdataTemp[key].selection = ChartdataTemp[key].selection.filter(
        (value) => value !== convertedId
      );
      if (parentClear) {
        ChartdataTemp[key].selection = [];
      }
      if (ChartdataTemp[key].selection.length === 0) {
        parentClear = true;
      }
    }

    console.log("label: chartDatatemp ", ChartdataTemp);

    setChartdataTrends(ChartdataTemp);
    setFetchCallTrends(true);
    setIsAllEnabledDownload(0);
    setLevel(-1);
  };

  // For down arrow
  const DrillDownAllTrends = () => {
    console.log("DRILLDOWN ChartdataTrends TRENDS", ChartdataTrends);
    // Get the keys in Chartdata
    const keysAlreadyInChartData = Object.keys(ChartdataTrends);

    // Clone Chartdata to avoid mutating the original object
    const ChartdataTemp = structuredClone(ChartdataTrends);

    // Retrieve SkipData from local storage
    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;

    SkipData.pop();

    let SkipLength = SkipData?.length;
    // Extract the IDs from SkipData
    const skipIds = SkipData?.map((item) => item.id);

    // Create empty arrays based on the number of keys in Chartdata
    const result = keysAlreadyInChartData.map(() => []);

    // Process the NotAllow array
    NotAllow.forEach((item) => {
      const splitArray = item.split("-");
      result.forEach((res, index) => {
        // For each result array, push the corresponding slice of splitArray
        res.push(splitArray.slice(0, index + 1).join("-"));
      });
    });

    // Create the payload array, filtering out duplicates
    let payloadArray = result.map((item) => {
      return [...new Set(item)]; // Remove duplicates
    });

    payloadArray = payloadArray.slice(0, payloadArray.length - 1);

    // Find the next element that is not in SkipData and has no selection or shorter selection
    let nextKey = null;
    let nextIndex = -1;
    SkipData?.forEach((skipItem) => {
      const skipId = skipItem?.id;

      // Check if ChartdataTemp includes the skipId as a key
      if (ChartdataTemp.hasOwnProperty(skipId)) {
        // Make the selection array same as the filter array
        ChartdataTemp[skipId].selection = ChartdataTemp[skipId].Filter;
      }
    });

    for (let i = 0; i < keysAlreadyInChartData.length - 1; i++) {
      const key = keysAlreadyInChartData[i];
      if (
        ChartdataTemp.hasOwnProperty(key) &&
        !skipIds?.includes(key) &&
        ChartdataTemp[key].selection.length === 0
      ) {
        nextKey = key;
        nextIndex = i;
        break;
      }
    }

    // If a nextKey is found and it's not in SkipData, set its selection with processedNotAllow data
    if (nextKey && ChartdataTemp.hasOwnProperty(nextKey)) {
      ChartdataTemp[nextKey].selection = payloadArray[nextIndex - SkipLength];
    }

    console.log("DrillDownAll - Updated ChartdataTemp:", ChartdataTemp);

    setChartdataTrends(ChartdataTemp);
    setFetchCallTrends(true);
    setIsAllEnabledDownload(0);
    setLevel(-1);
  };

  // For tree button
  const DrillAllTrends = () => {
    const keysArrayOfChartData = Object.keys(ChartdataTrends);
    const ChartdataTemp = structuredClone(ChartdataTrends);
    console.log("DRILLALLTRENDS", ChartdataTemp);

    // Create an array of empty arrays for each key in Chartdata
    const result = keysArrayOfChartData.map(() => []);

    // Split each item in NotAllow by "-" and push the resulting arrays into result
    NotAllow.forEach((item) => {
      const splitArray = item.split("-");
      result.forEach((res, index) => {
        // For each result array, push the corresponding slice of splitArray
        res.push(splitArray.slice(0, index + 1).join("-"));
      });
    });

    // Create the payload array, filtering out duplicates
    let payloadArray = result.map((item) => {
      return [...new Set(item)]; // Remove duplicates
    });

    console.log(
      "Payload Array after nonclicklist:",
      payloadArray.slice(0, payloadArray.length - 1)
    );

    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;

    // Assign Selection to Skipdata
    SkipData.pop();
    console.log("SKipDataTrends ", SkipData);
    // Step 1: Update `ChartdataTemp` for ids in `SkipData`
    // Loop through the sliced SkipData
    SkipData?.forEach((skipItem) => {
      const skipId = skipItem.id;

      // Check if ChartdataTemp includes the skipId as a key
      if (ChartdataTemp.hasOwnProperty(skipId)) {
        // Make the selection array same as the filter array
        ChartdataTemp[skipId].selection = ChartdataTemp[skipId].Filter;
      }
    });

    payloadArray = payloadArray.slice(0, payloadArray.length - 1);
    let SkipLength = SkipData?.length;

    // Step 2: Update `ChartdataTemp` with `payloadArray` after handling `SkipData`
    keysArrayOfChartData.slice(0, -1).forEach((key, index) => {
      if (!SkipData?.some((skipItem) => skipItem.id === key)) {
        if (ChartdataTemp.hasOwnProperty(key)) {
          // Set the selection to the corresponding payloadArray values
          console.log("nonclicklist ", key, "   ", payloadArray[index]);
          ChartdataTemp[key].selection = payloadArray[index - SkipLength] || [];
        }
      }
    });
    setIsAllEnabled(1);
    setChartdataTrends(ChartdataTemp);
    setFetchCallTrends(true);
    setIsAllEnabledDownload(1);
    setLevel(-1);
  };

  // For reset button
  const ResetAllTrends = () => {
    let ChartdataTemp = {};
    for (const filter in ChartdataTrends) {
      ChartdataTemp[filter] = ChartdataTrends[filter];
      ChartdataTemp[filter].selection = [];
    }
    setLevel(DefaultLevel);
    setReset(1);
    setChartdataTrends(ChartdataTemp);
    setFetchCallTrends(true);
    setIsAllEnabledDownload(0);
  };

  // For arrow up
  const DrillUpAllTrends = () => {
    const keysAlreadyInChartData = Object.keys(ChartdataTrends);

    // Clone Chartdata to avoid mutating the original object
    const ChartdataTemp = structuredClone(ChartdataTrends);

    // Retrieve SkipData from local storage
    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;
    SkipData.pop();
    // Extract the IDs from SkipData
    const skipIds = SkipData?.map((item) => item.id);

    // Identify the last key in ChartdataTemp that has a non-empty selection and is not in SkipData
    const lastKey = keysAlreadyInChartData.reverse().find((key) => {
      return (
        ChartdataTemp[key].selection &&
        ChartdataTemp[key].selection.length > 0 &&
        !skipIds?.includes(key)
      );
    });

    // If a lastKey is found and it's not in SkipData, clear its selection
    if (lastKey && ChartdataTemp.hasOwnProperty(lastKey)) {
      ChartdataTemp[lastKey].selection = [];
    }

    console.log("DrillUpAll - Updated ChartdataTemp:", ChartdataTemp);

    setChartdataTrends(ChartdataTemp);
    setFetchCallTrends(true);
    setIsAllEnabledDownload(1);
    setLevel(-1);
  };

  // end for trends=========================================================================================

  const HandleZoom = () => {
    setLoader(true);
    const categoriesTemp = JSON.parse(JSON.stringify(categories));
    const seriesTemp = JSON.parse(JSON.stringify(series));

    setCategories([]);
    setSeries([]);

    setZoomIn(!ZoomIn);
    setTimeout(function () {
      setCategories(categoriesTemp);
      setSeries(seriesTemp);
      // //setLoader(false);
    }, 1000);
  };

  const ResetFilters = () => {
    // var active_filter = []
    // for (let i = 0; i < ActiveFilters.length; i++) {
    //   var obj = ActiveFilters[i];
    //   if (ActiveChart === "Measurement") {
    //     obj['Selected'] = [];
    //     setMResetFilterFlag(false)
    //   }
    //   if (ActiveChart === "Trend_Analysis") {
    //     obj['Selected'] = [];
    //     setMResetFilterFlag(false)
    //   }
    //   if (ActiveChart === "Comparison") {
    //     obj['Selected1'] = [];
    //     obj['Selected2'] = [];
    //     setCResetFilterFlag(false)
    //   }
    //   active_filter.push(obj)
    // }
    // var comp_active_filter = []
    // for (let k = 0; k < CompActiveFilters.length; k++) {
    //   var obj1 = CompActiveFilters[k];
    //   if (ActiveChart === "Measurement") {
    //     obj1['Selected'] = [];
    //     setMResetFilterFlag(false)
    //   }
    //   if (ActiveChart === "Trend_Analysis") {
    //     obj1['Selected'] = [];
    //     setMResetFilterFlag(false)
    //   }
    //   if (ActiveChart === "Comparison") {
    //     obj1['Selected1'] = [];
    //     obj1['Selected2'] = [];
    //     setCResetFilterFlag(false)
    //   }
    //   comp_active_filter.push(obj1)
    // }
    // //Set analytical filters data in localstorage
    // const AnalyticFilterTemp = {
    //   AllFilters: AllFilters,
    //   Fixed: Fixed,
    //   ActiveFilters: active_filter
    // };
    // localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));
    // const CampaignAnalyticFilter = {
    //   CompAllFilters: CompAllFilters,
    //   CompActiveFilters: comp_active_filter
    // };
    // localStorage.setItem("CampaignAnalyticFilter", JSON.stringify(CampaignAnalyticFilter));
    // setActiveFilters(active_filter)
    // setCompActiveFilters(comp_active_filter)
  };

  const downloadDataM = (e) => {
    e.preventDefault();
    setLoader(true);
    axiosDownload
      .post("get_grain_contrib_new/", {
        brand: Analyse.Brand,
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        timeframe: timeframe,
        startdate: DefaultStartDate,
        enddate: DefaultEndDate,
        is_all_enabled: IsAllEnabled,
        reset: Reset,
        chartdata: Chartdata,
        theme: localStorage.getItem("theme"),
        LightThemeColorCode: [
          localStorage.getItem("ColorCode"),
          LightThemeHexCode[localStorage.getItem("ColorCode")],
        ],
        download: 1,
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  const downloadPDFM = async (e) => {
    e.preventDefault();
    setLoader(true);
    const chartContainers = [document.getElementById("campaign_chart")];
    if (chartContainers.every((container) => container !== null)) {
      try {
        // setLoader(true);
        const dataUrls = await Promise.all(
          chartContainers.map((container) => domtoimage.toPng(container))
        );
        // setLoader(true);
        const response = await axiosDownload.post("get_grain_contrib_new/", {
          brand: Analyse.Brand,
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: timeframe,
          startdate: DefaultStartDate,
          enddate: DefaultEndDate,
          is_all_enabled: IsAllEnabled,
          reset: Reset,
          chartdata: Chartdata,
          theme: localStorage.getItem("theme"),
          LightThemeColorCode: [
            localStorage.getItem("ColorCode"),
            LightThemeHexCode[localStorage.getItem("ColorCode")],
          ],
          download: 1,
          download_type: "PDF",
        });
        //setLoader(false);
        // console.log("excel: ", response.data);
        const pdfResponse = await axiosChartDownload.post(
          "download_pdf/create/",
          {
            images: dataUrls.map((dataUrl) => dataUrl.split(",")[1]),
            document: {
              table1_data: response.data.data.table1_data,
              titlename: "Campaign Effectiveness",
              footer_content: "Private & Confidential",
              table_heading1: "Measurement Deepdive",
              image_heading1: "Measurement Deepdive",
              username: userName,
              timeframe: timeframe,
              start_date: DefaultStartDate,
              end_date: DefaultEndDate,
              category: "",
              chartdata: Chartdata,
              theme: localStorage.getItem("theme"),
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              tabname: "grain_contri",
            },
          }
        );
        if (pdfResponse.data.error === 1) {
          toast.error(pdfResponse.data.erroMsg);
          setLoader(false);
        } else {
          // console.log("response pdf: ", pdfResponse.data);
          setLoader(false);
          var link = document.createElement("a");
          link.target = "_blank";
          link.href = pdfResponse.data.pdf_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error while generating pdf:", error);
      }
    }
  };

  const downloadDataC1 = (e) => {
    e.preventDefault();
    setLoader(true);
    axiosDownload
      .post("get_grain_contrib_new/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        timeframe: timeframeComp1,
        startdate: DefaultStartDateComp1,
        enddate: DefaultEndDateComp1,
        chartdata: ChartdataComp1,
        is_all_enabled: IsAllEnabled,
        reset: Reset,
        chart: "comp-1",
        theme: localStorage.getItem("theme"),
        LightThemeColorCode: [
          localStorage.getItem("ColorCode"),
          LightThemeHexCode[localStorage.getItem("ColorCode")],
        ],
        download: 1,
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  const downloadPDFC1 = async (e) => {
    e.preventDefault();
    setLoader(true);
    const chartContainers = [document.getElementById("Comp1")];
    if (chartContainers.every((container) => container !== null)) {
      try {
        // setLoader(true);
        const dataUrls = await Promise.all(
          chartContainers.map((container) => domtoimage.toPng(container))
        );
        // setLoader(true);
        const response = await axiosDownload.post("get_grain_contrib_new/", {
          brand: Analyse.Brand,

          kpi: Analyse.KPI,
          timeframe: timeframeComp1,
          startdate: DefaultStartDateComp1,
          enddate: DefaultEndDateComp1,
          chartdata: ChartdataComp1,
          is_all_enabled: IsAllEnabled,
          reset: Reset,
          chart: "comp-1",
          theme: localStorage.getItem("theme"),
          LightThemeColorCode: [
            localStorage.getItem("ColorCode"),
            LightThemeHexCode[localStorage.getItem("ColorCode")],
          ],
          download: 1,
          download_type: "PDF",
        });
        //setLoader(false);
        // console.log("excel: ", response.data);
        const pdfResponse = await axiosChartDownload.post(
          "download_pdf/create/",
          {
            images: dataUrls.map((dataUrl) => dataUrl.split(",")[1]),
            document: {
              table1_data: response.data.data.table1_data,
              titlename: "Campaign Effectiveness",
              footer_content: "Private & Confidential",
              table_heading1: "Measurement Deepdive",
              image_heading1: "Measurement Deepdive",
              username: userName,
              timeframe: timeframeComp1,
              start_date: DefaultStartDateComp1,
              end_date: DefaultEndDateComp1,
              category: "",
              chartdata: ChartdataComp1,
              theme: localStorage.getItem("theme"),
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              tabname: "grain_contri",
            },
          }
        );
        if (pdfResponse.data.error === 1) {
          toast.error(pdfResponse.data.erroMsg);
          setLoader(false);
        } else {
          // console.log("response pdf: ", pdfResponse.data);
          setLoader(false);
          var link = document.createElement("a");
          link.target = "_blank";
          link.href = pdfResponse.data.pdf_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error while generating pdf:", error);
      }
    }
  };

  const downloadDataC2 = (e) => {
    e.preventDefault();
    setLoader(true);
    axiosDownload
      .post("get_grain_contrib_new/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        timeframe: timeframeComp1,
        startdate: DefaultStartDateComp1,
        enddate: DefaultEndDateComp1,
        chartdata: ChartdataComp1,
        is_all_enabled: IsAllEnabled,
        reset: Reset,
        chart: "comp-2",
        theme: localStorage.getItem("theme"),
        LightThemeColorCode: [
          localStorage.getItem("ColorCode"),
          LightThemeHexCode[localStorage.getItem("ColorCode")],
        ],
        download: 1,
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  const downloadPDFC2 = async (e) => {
    e.preventDefault();
    setLoader(true);
    const chartContainers = [document.getElementById("Comp2")];
    if (chartContainers.every((container) => container !== null)) {
      try {
        // setLoader(true);
        const dataUrls = await Promise.all(
          chartContainers.map((container) => domtoimage.toPng(container))
        );
        // setLoader(true);
        const response = await axiosDownload.post("get_grain_contrib_new/", {
          brand: Analyse.Brand,

          kpi: Analyse.KPI,
          timeframe: timeframeComp1,
          startdate: DefaultStartDateComp1,
          enddate: DefaultEndDateComp1,
          chartdata: ChartdataComp1,
          is_all_enabled: IsAllEnabled,
          reset: Reset,
          chart: "comp-2",
          theme: localStorage.getItem("theme"),
          LightThemeColorCode: [
            localStorage.getItem("ColorCode"),
            LightThemeHexCode[localStorage.getItem("ColorCode")],
          ],
          download: 1,
          download_type: "PDF",
        });
        //setLoader(false);
        // console.log("excel: ", response.data);
        const pdfResponse = await axiosChartDownload.post(
          "download_pdf/create/",
          {
            images: dataUrls.map((dataUrl) => dataUrl.split(",")[1]),
            document: {
              table1_data: response.data.data.table1_data,
              titlename: "Campaign Effectiveness",
              footer_content: "Private & Confidential",
              table_heading1: "Measurement Deepdive",
              image_heading1: "Measurement Deepdive",
              username: userName,
              timeframe: timeframeComp2,
              start_date: DefaultStartDateComp2,
              end_date: DefaultEndDateComp2,
              category: "",
              chartdata: ChartdataComp2,
              theme: localStorage.getItem("theme"),
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              tabname: "grain_contri",
            },
          }
        );
        if (pdfResponse.data.error === 1) {
          toast.error(pdfResponse.data.erroMsg);
          setLoader(false);
        } else {
          // console.log("response pdf: ", pdfResponse.data);
          setLoader(false);
          var link = document.createElement("a");
          link.target = "_blank";
          link.href = pdfResponse.data.pdf_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error while generating pdf:", error);
      }
    }
  };

  const downloadDataT = (e) => {
    e.preventDefault();
    setLoader(true);
    axiosDownload
      .post("get_contributions_stack/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        timeframe: timeframeTrends,
        startdate: DefaultStartDateTrends,
        enddate: DefaultEndDateTrends,
        is_all_enabled: IsAllEnabled,
        reset: Reset,
        chartdata: ChartdataTrends,
        theme: localStorage.getItem("theme"),
        download: 1,
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  const downloadPDFT = async (e) => {
    e.preventDefault();
    setLoader(true);
    const chartContainers = [document.getElementById("trends_chart")];
    if (chartContainers.every((container) => container !== null)) {
      try {
        // setLoader(true);
        const dataUrls = await Promise.all(
          chartContainers.map((container) => domtoimage.toPng(container))
        );
        // setLoader(true);
        const response = await axiosDownload.post("get_contributions_stack/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: timeframeTrends,
          startdate: DefaultStartDateTrends,
          enddate: DefaultEndDateTrends,
          is_all_enabled: IsAllEnabled,
          level: level,
          reset: Reset,
          chartdata: ChartdataTrends,
          theme: localStorage.getItem("theme"),
          download: 1,
          download_type: "PDF",
        });
        //setLoader(false);
        // console.log("excel: ", response.data);
        const pdfResponse = await axiosChartDownload.post(
          "download_pdf/create/",
          {
            images: dataUrls.map((dataUrl) => dataUrl.split(",")[1]),
            document: {
              table1_data: response.data.table1_data,
              titlename: "Campaign Effectiveness",
              footer_content: "Private & Confidential",
              table_heading1: "Trend Analysis",
              image_heading1: "Trend Analysis",
              username: userName,
              timeframe: timeframeTrends,
              start_date: DefaultStartDateTrends,
              end_date: DefaultEndDateTrends,
              category: "",
              chartdata: ChartdataTrends,
              theme: localStorage.getItem("theme"),
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              tabname: "grain_contri",
            },
          }
        );
        if (pdfResponse.data.error === 1) {
          toast.error(pdfResponse.data.erroMsg);
          setLoader(false);
        } else {
          // console.log("response pdf: ", pdfResponse.data);
          setLoader(false);
          var link = document.createElement("a");
          link.target = "_blank";
          link.href = pdfResponse.data.pdf_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error while generating pdf:", error);
      }
    }
  };

  const [HFilters, setHFilters] = useState([]);
  // console.log("HFilters: ", HFilters);
  // console.log("Chartdata: ", Object.keys(Chartdata));

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedCheckboxesH1, setSelectedCheckboxesH1] = useState([]);

  const [selectedCheckboxesH2, setSelectedCheckboxesH2] = useState([]);

  // console.log("selectedCheckboxes: ", selectedCheckboxes);

  const filteredKeys = Object.keys(ChartdataForHierarchyFilters()[0]).filter(
    (key) => !key.startsWith("C")
  );

  useEffect(() => {
    const fetchHierarchyFilters = async () => {
      try {
        const response = await axios.post("hieracrhy_data/", {
          hierarchy_list: filteredKeys,
          brand: Analyse.Brand,
        });

        const data = response.data;
        // console.log(data);
        setHFilters(data.data);
        // localStorage.setItem("hierarchyFilters", JSON.stringify(data.data));
      } catch (error) {
        console.error("Failed to fetch:", error);
      }
    };
    fetchHierarchyFilters();
  }, [
    ChartdataForHierarchyFilters()[0],
    ChartdataForHierarchyFilters()[1],
    Analyse.Brand,
  ]);

  const [CFilters, setCFilters] = useState([]);
  // console.log("HFilters: ", HFilters);
  // console.log("Chartdata: ", Object.keys(Chartdata));

  const [selectedCheckboxesC, setSelectedCheckboxesC] = useState([]);
  // console.log("selectedCheckboxes: ", selectedCheckboxes);
  const [selectedCheckboxesC1, setSelectedCheckboxesC1] = useState([]);
  const [selectedCheckboxesC2, setSelectedCheckboxesC2] = useState([]);

  const filteredKeysC = Object.keys(ChartdataForHierarchyFilters()[0]).filter(
    (key) => !key.startsWith("H")
  );

  useEffect(() => {
    const fetchChannelFilters = async () => {
      console.log("channel_hierarchy ", ChartdataForHierarchyFilters()[0]);
      try {
        const response = await axios.post("channel_hierarcy/", {
          channel_list: filteredKeysC,
          brand: Analyse.Brand,
        });

        const data = response.data;
        // console.log(data);
        setCFilters(data.data);
        // localStorage.setItem("channelFilters", JSON.stringify(data.data));
      } catch (error) {
        console.error("Failed to fetch:", error);
      }
    };
    fetchChannelFilters();
  }, [
    ChartdataForHierarchyFilters()[0],
    ChartdataForHierarchyFilters()[1],
    Analyse.Brand,
  ]);

  const updateChartData = (
    changeFilter = [],
    channelFilter = [],
    filterAdd = false
  ) => {
    // Initialize the result object
    const result = {};
    console.log("resultt updatedChartData");
    const filterdata = JSON.parse(localStorage.getItem("AnalyticFilter"));
    const ChannelFilterData = JSON.parse(
      localStorage.getItem("CampaignAnalyticFilter")
    );

    // Populate the result object with Fixed filters
    filterdata.Fixed.forEach((item) => {
      result[item.id] = {
        selection: [],
        Filter: [item.value],
      };
    });

    if (changeFilter.length === 0) {
      // Populate the result object with AllFilters that are not in Fixed
      filterdata.ActiveFilters.forEach((item) => {
        if (!result[item.name]) {
          result[item.name] = {
            selection: [],
            Filter: [],
          };
        }
      });
    } else {
      changeFilter.forEach((item) => {
        if (!result[item.name]) {
          result[item.name] = {
            selection: [],
            Filter: [],
          };
        }
      });
    }

    if (channelFilter.length === 0) {
      // Populate the result object with AllFilters that are not in Fixed
      ChannelFilterData.CompActiveFilters.forEach((item) => {
        if (!result[item.name]) {
          result[item.name] = {
            selection: [],
            Filter: [],
          };
        }
      });
    } else {
      channelFilter.forEach((item) => {
        if (!result[item.name]) {
          result[item.name] = {
            selection: [],
            Filter: [],
          };
        }
      });
    }
    console.log(
      "resultt updates ",
      result,
      " ",
      ChartdataComp1,
      "    ",
      ChartdataComp2
    );

    if (ActiveChart === "Measurement") {
      const ChartdataTemp = structuredClone(Chartdata);
      if (channelFilter.length == 0 && filterAdd) {
        const result1 = {};
        Object.keys(result).forEach((key) => {
          result1[key] = {
            selection: JSON.parse(
              JSON.stringify(ChartdataTemp[key]?.selection || [])
            ),
            Filter: JSON.parse(
              JSON.stringify(ChartdataTemp[key]?.Filter || [])
            ),
          };
        });

        setChartdata(result1);

        setLevel(-1);
      } else {
        console.log("resultt ", result, " ", Chartdata);

        setChartdata(result);

        setLevel(DefaultLevel);
      }
      setFetchCall(true);
    } else if (ActiveChart === "Comparison") {
      const ChartdataTemp = structuredClone(ChartdataComp1);
      const ChartdataTemp2 = structuredClone(ChartdataComp2);
      if (channelFilter.length == 0 && filterAdd) {
        const result1 = {};
        const result2 = {};
        Object.keys(result).forEach((key) => {
          result1[key] = {
            selection: JSON.parse(
              JSON.stringify(ChartdataTemp[key]?.selection || [])
            ),
            Filter: JSON.parse(
              JSON.stringify(ChartdataTemp[key]?.Filter || [])
            ),
          };
        });
        Object.keys(result).forEach((key) => {
          result2[key] = {
            selection: JSON.parse(
              JSON.stringify(ChartdataTemp2[key]?.selection || [])
            ),
            Filter: JSON.parse(
              JSON.stringify(ChartdataTemp2[key]?.Filter || [])
            ),
          };
        });

        setChartdataComp1(result1);
        setChartdataComp2(result2);

        setLevel(-1);
      } else {
        console.log("resultt ", result, " ", Chartdata);
        setChartdataComp1(result);
        setChartdataComp2(result);
        setLevel(DefaultLevel);
      }
      setFetchCallComp1(true);
      setFetchCallComp2(true);
    } else if (ActiveChart === "Trend_Analysis") {
      const ChartdataTemp = structuredClone(ChartdataTrends);
      if (channelFilter.length == 0 && filterAdd) {
        const result1 = {};
        Object.keys(result).forEach((key) => {
          result1[key] = {
            selection: JSON.parse(
              JSON.stringify(ChartdataTemp[key]?.selection || [])
            ),
            Filter: JSON.parse(
              JSON.stringify(ChartdataTemp[key]?.Filter || [])
            ),
          };
        });

        setChartdataTrends(result1);

        setLevel(-1);
      } else {
        console.log("resultt ", result, " ", Chartdata);

        setChartdataTrends(result);

        setLevel(DefaultLevel);
      }

      setFetchCallTrends(true);
    }

    setSelectedCheckboxes([]);
    setSelectedCheckboxesC([]);
    setSelectedCheckboxesC1([]);
    setSelectedCheckboxesC2([]);
    setSelectedCheckboxesH1([]);
    setSelectedCheckboxesH2([]);
    setNewHierarchyBreadCrumb("");
    setNewHierarchyBreadCrumb2("");
    setNewChannelBreadCrumb("");
    setNewChannelBreadCrumb2("");
  };
  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1199) {
        setIsExpanded(false);
      } else {
        setIsExpanded(true);
      }
    };

    window.addEventListener("resize", handleResize);

    // Call it initially to set the correct state based on the initial window size
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="skewb-div">
      {loader ? <Loader /> : null}

      <ToastContainer theme="colored" />
      {/* <Topbar />
      <Menubar /> */}

      <div className="main-content-div">
        <div className="page-content-div">
          <div className="select-filter-dropdown-div">
            <div
              className="filter-dropdown"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <span className="text-small">
                <i
                  className="fas fa-filter"
                  style={{ marginRight: "5px", fontSize: ".8rem" }} // Adds spacing and adjusts icon size
                ></i>{" "}
                Select Filter
              </span>
              <button
                style={{
                  background: "none",
                  border: "none",
                  color: "white",
                  fontSize: ".8rem",
                  cursor: "pointer",
                  transform: isExpanded ? "rotate(180deg)" : "rotate(0)",
                  transition: "transform 0.3s ease",
                }}
              >
                ▼
              </button>
            </div>
            {isExpanded && (
              <div className="filter-class">
                {ActiveChart !== "Comparison" && (
                  <AllHeirarchyFilter
                    setHierarchySelectionBreadCrumb={
                      setHierarchySelectionBreadCrumb
                    }
                    ActiveChart={ActiveChart}
                    OnlyHeirarchyFilter={false}
                    Fixed={Fixed}
                    ActiveFilters={ActiveFilters}
                    AllFilters={AllFilters}
                    AddHeirarchyFilter={AddHeirarchyFilter}
                    RemoveHeirarchyFilter={RemoveHeirarchyFilter}
                    IsInAllHeirarchyFilters={IsInAllHeirarchyFilters}
                    Chartdata={JSON.parse(
                      JSON.stringify(ChartdataForHierarchyFilters()[0])
                    )}
                    setChartdata={returnSetChartdata()[0]}
                    setFetchCall={returnSetFetchCall()[0]}
                    HFilters={HFilters}
                    setActiveFilters={setActiveFilters}
                    selectedCheckboxes={selectedCheckboxes}
                    setSelectedCheckboxes={setSelectedCheckboxes}
                    setLevel={setLevel}
                  />
                )}

                {ActiveChart !== "Comparison" && (
                  <AllCampaignFilter
                    setChannelSelectionBreadCrumb={
                      setChannelSelectionBreadCrumb
                    }
                    key={filteredKeysC}
                    updateChartData={updateChartData}
                    CompActiveFilters={CompActiveFilters}
                    CompAllFilters={CompAllFilters}
                    AddCompFilter={AddCompFilter}
                    RemoveCompFilter={RemoveCompFilter}
                    IsInAllCompFilters={IsInAllCompFilters}
                    Chartdata={JSON.parse(
                      JSON.stringify(ChartdataForHierarchyFilters()[0])
                    )}
                    setChartdata={returnSetChartdata()[0]}
                    setFetchCall={returnSetFetchCall()[0]}
                    setCompActiveFilters={setCompActiveFilters}
                    CFilters={CFilters}
                    selectedCheckboxesC={selectedCheckboxesC}
                    setLevel={setLevel}
                    setSelectedCheckboxesC={setSelectedCheckboxesC}
                  />
                )}
                {ActiveChart === "Comparison" && (
                  <HeirarchyFilterComp
                    setNewHierarchyBreadCrumb={setNewHierarchyBreadCrumb}
                    setNewHierarchyBreadCrumb2={setNewHierarchyBreadCrumb2}
                    ActiveChart={ActiveChart}
                    OnlyHeirarchyFilter={false}
                    Fixed={Fixed}
                    ActiveFilters={ActiveFilters}
                    AllFilters={AllFilters}
                    AddHeirarchyFilter={AddHeirarchyFilter}
                    RemoveHeirarchyFilter={RemoveHeirarchyFilter}
                    IsInAllHeirarchyFilters={IsInAllHeirarchyFilters}
                    Chartdata2={JSON.parse(
                      JSON.stringify(ChartdataForHierarchyFilters()[1])
                    )}
                    Chartdata={JSON.parse(
                      JSON.stringify(ChartdataForHierarchyFilters()[0])
                    )}
                    setChartdata={returnSetChartdata()[0]}
                    setChartdata2={returnSetChartdata()[1]}
                    setFetchCall={returnSetFetchCall()[0]}
                    setFetchCall2={returnSetFetchCall()[1]}
                    HFilters={HFilters}
                    setActiveFilters={setActiveFilters}
                    selectedCheckboxesH1={selectedCheckboxesH1}
                    setSelectedCheckboxesH1={setSelectedCheckboxesH1}
                    selectedCheckboxesH2={selectedCheckboxesH2}
                    setLevel={setLevel}
                    setSelectedCheckboxesH2={setSelectedCheckboxesH2}
                  />
                )}

                {ActiveChart === "Comparison" && (
                  <ChannelFilterComp
                    key={filteredKeysC}
                    setNewChannelBreadCrumb={setNewChannelBreadCrumb}
                    setNewChannelBreadCrumb2={setNewChannelBreadCrumb2}
                    updateChartData={updateChartData}
                    CompActiveFilters={CompActiveFilters}
                    CompAllFilters={CompAllFilters}
                    AddCompFilter={AddCompFilter}
                    RemoveCompFilter={RemoveCompFilter}
                    IsInAllCompFilters={IsInAllCompFilters}
                    // Chartdata={Chartdata}
                    // setChartdata={setChartdata}

                    Chartdata={JSON.parse(
                      JSON.stringify(ChartdataForHierarchyFilters()[0])
                    )}
                    setChartdata={returnSetChartdata()[0]}
                    Chartdata2={JSON.parse(
                      JSON.stringify(ChartdataForHierarchyFilters()[1])
                    )}
                    setChartdata2={returnSetChartdata()[1]}
                    setFetchCall2={returnSetFetchCall()[1]}
                    setFetchCall={returnSetFetchCall()[0]}
                    setCompActiveFilters={setCompActiveFilters}
                    CFilters={CFilters}
                    selectedCheckboxesC1={selectedCheckboxesC1}
                    setSelectedCheckboxesC1={setSelectedCheckboxesC1}
                    selectedCheckboxesC2={selectedCheckboxesC2}
                    setLevel={setLevel}
                    setSelectedCheckboxesC2={setSelectedCheckboxesC2}
                  />
                )}

                {false && (
                  <div className="col-xl-2 mt-1">
                    <div className="card my-1 mt-0">
                      <div
                        className="card-body cursor-pointer d-flex align-items-center justify-content-center custom-selected-filter"
                        onClick={() => {
                          setFilterTabShow(true);
                        }}
                      >
                        <h6 className="m-0 text-center primary-text">
                          <i className="fa fa-filter" aria-hidden="true"></i>{" "}
                          Selected Filter
                        </h6>
                        {ActiveChart === "Measurement" && MResetFilterFlag ? (
                          <h6 className="m-0 text-center primary-text cursor-pointer custom-reset">
                            <p
                              className="mx-2 m-0"
                              title="Reset Filter"
                              onClick={() => {
                                ResetFilters();
                              }}
                            >
                              {" "}
                              &#8635;{" "}
                            </p>
                          </h6>
                        ) : null}
                        {ActiveChart === "Comparison" && CResetFilterFlag ? (
                          <h6 className="m-0 text-center primary-text cursor-pointer custom-reset">
                            <p
                              className="mx-2 m-0"
                              title="Reset Filter"
                              onClick={() => {
                                ResetFilters();
                              }}
                            >
                              {" "}
                              &#8635;{" "}
                            </p>
                          </h6>
                        ) : null}

                        {ActiveChart === "Trend_Analysis" &&
                        MResetFilterFlag ? (
                          <h6 className="m-0 text-center primary-text cursor-pointer custom-reset">
                            <p
                              className="mx-2 m-0"
                              title="Reset Filter"
                              onClick={() => {
                                ResetFilters();
                              }}
                            >
                              {" "}
                              &#8635;{" "}
                            </p>
                          </h6>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <div
            className="col-sm-6 col-md-6 col-lg-6 d-flex ce-filter justify-content-end my-1 mb-1"
            style={{ width: "100%" }}
          >
            <button
              className={
                ActiveChart === "Measurement"
                  ? "m-0 px-2 mx-2 cursor-pointer  custom-input-box lt-st  tab-class-clicked "
                  : "m-0 px-2 mx-2 cursor-pointer text-lable custom-input-box tab-class-unclicked"
              }
              type="button"
              onClick={() => setActiveChart("Measurement")}
            >
              Measurement Chart
            </button>
            <button
              className={
                ActiveChart === "Comparison"
                  ? "m-0 px-2 mx-2 cursor-pointer  custom-input-box lt-st tab-class-clicked"
                  : "m-0 px-2 mx-2 cursor-pointer text-lable custom-input-box tab-class-unclicked"
              }
              type="button"
              onClick={() => setActiveChart("Comparison")}
            >
              Comparison Charts
            </button>
            {/* Trend Analysis start */}
            <button
              className={
                ActiveChart === "Trend_Analysis"
                  ? "m-0 px-2 mx-2 cursor-pointer  custom-input-box lt-st  tab-class-clicked"
                  : "m-0 px-2 mx-2 cursor-pointer text-lable custom-input-box tab-class-unclicked"
              }
              type="button"
              onClick={() => setActiveChart("Trend_Analysis")}
            >
              Trend Analysis
            </button>
            {/* Trend Analysis end */}
          </div>

          {ActiveChart === "Measurement" ? (
            <Measurement
              hierarchySelectionBreadCrumb={hierarchySelectionBreadCrumb}
              channelSelectionBreadCrumb={channelSelectionBreadCrumb}
              timeframe={timeframe}
              setTimeframe={setTimeframe}
              onChangeHandler={onChangeHandler}
              TimeFramesAll={TimeFramesAll}
              DefaultStartDate={DefaultStartDate}
              DefaultEndDate={DefaultEndDate}
              setDefaultStartDate={setDefaultStartDate}
              setDefaultEndDate={setDefaultEndDate}
              FromDatesAll={FromDatesAll}
              ToDatesAll={ToDatesAll}
              setFetchCall={setFetchCall}
              Summary={Summary}
              AllFilters={AllFilters}
              ActiveFilters={ActiveFilters}
              FooterNotes={FooterNotes}
              categories={categories}
              series={series}
              xtitle={xtitle}
              ytitle={ytitle}
              charttitle={charttitle}
              DrillUp={DrillUp}
              DrillDown={DrillDown}
              DrillUpAll={DrillUpAll}
              DrillDownAll={DrillDownAll}
              DrillAll={DrillAll}
              ResetAll={ResetAll}
              BreadCrumbs={BreadCrumbs}
              // setZoomIn={setZoomIn}
              HandleZoom={HandleZoom}
              ZoomIn={ZoomIn}
              NotAllow={NotAllow}
              ShowSummary={ShowSummary}
              // setDownloadchart={setDownloadchart}
              // Downloadchart={Downloadchart}
              setLoader={setLoader}
              Fixed={Fixed}
              setIsDownloadingChart={setIsDownloadingChart}
              setChartContainerID={setChartContainerID}
              setDownloadedImageName={setDownloadedImageName}
              setDownloadingChartTitle={setDownloadingChartTitle}
              downloadDataM={downloadDataM}
              setHierarchy={setHierarchy}
              downloadPDFM={downloadPDFM}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setTimeFrameChart={setTimeFrameChart}
              startdate={startdate}
              enddate={enddate}
              submittedTimeFrame={submittedTimeFrame}
              setSubmittedTimeFrame={setSubmittedTimeFrame}
              submittedStartDate={submittedStartDate}
              setSubmittedStartDate={setSubmittedStartDate}
              submittedEndDate={submittedEndDate}
              setSubmittedEndDate={setSubmittedEndDate}
            />
          ) : null}
          {ActiveChart === "Comparison" ? (
            <Comparison
              newChannelBreadCrumb={newChannelBreadCrumb}
              newHierarchyBreadCrumb={newHierarchyBreadCrumb}
              newChannelBreadCrumb2={newChannelBreadCrumb2}
              newHierarchyBreadCrumb2={newHierarchyBreadCrumb2}
              onChangeHandlerComp1={onChangeHandlerComp1}
              onChangeHandlerComp2={onChangeHandlerComp2}
              setTimeframeComp1={setTimeframeComp1}
              setTimeframeComp2={setTimeframeComp2}
              timeframeComp1={timeframeComp1}
              TimeFramesAllComp1={TimeFramesAllComp1}
              DefaultStartDateComp1={DefaultStartDateComp1}
              DefaultEndDateComp1={DefaultEndDateComp1}
              setDefaultStartDateComp1={setDefaultStartDateComp1}
              setDefaultEndDateComp1={setDefaultEndDateComp1}
              FromDatesAllComp1={FromDatesAllComp1}
              ToDatesAllComp1={ToDatesAllComp1}
              setFetchCallComp1={setFetchCallComp1}
              categoriesComp1={categoriesComp1}
              seriesComp1={seriesComp1}
              xtitleComp1={xtitleComp1}
              ytitleComp1={ytitleComp1}
              charttitleComp1={charttitleComp1}
              DrillDownComp1={DrillDownComp1}
              DrillUpComp1={DrillUpComp1}
              timeframeComp2={timeframeComp2}
              TimeFramesAllComp2={TimeFramesAllComp2}
              DefaultStartDateComp2={DefaultStartDateComp2}
              DefaultEndDateComp2={DefaultEndDateComp2}
              setDefaultStartDateComp2={setDefaultStartDateComp2}
              setDefaultEndDateComp2={setDefaultEndDateComp2}
              FromDatesAllComp2={FromDatesAllComp2}
              ToDatesAllComp2={ToDatesAllComp2}
              setFetchCallComp2={setFetchCallComp2}
              categoriesComp2={categoriesComp2}
              seriesComp2={seriesComp2}
              xtitleComp2={xtitleComp2}
              ytitleComp2={ytitleComp2}
              charttitleComp2={charttitleComp2}
              DrillDownComp2={DrillDownComp2}
              DrillUpComp2={DrillUpComp2}
              DrillUpAll1={DrillUpAll1}
              DrillDownAll1={DrillDownAll1}
              DrillAll1={DrillAll1}
              ResetAll1={ResetAll1}
              BreadCrumbsComp1={BreadCrumbsComp1}
              DrillUpAll2={DrillUpAll2}
              DrillDownAll2={DrillDownAll2}
              DrillAll2={DrillAll2}
              ResetAll2={ResetAll2}
              BreadCrumbsComp2={BreadCrumbsComp2}
              FooterNotes={FooterNotes}
              NotAllow={NotAllow}
              setIsDownloadingChart={setIsDownloadingChart}
              setChartContainerID={setChartContainerID}
              setDownloadedImageName={setDownloadedImageName}
              setDownloadingChartTitle={setDownloadingChartTitle}
              downloadDataC1={downloadDataC1}
              downloadDataC2={downloadDataC2}
              setHierarchy={setHierarchy}
              downloadPDFC1={downloadPDFC1}
              downloadPDFC2={downloadPDFC2}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setTimeFrameChart={setTimeFrameChart}
              startdate={startdate}
              enddate={enddate}
              //
              Chartdata={ChartdataForHierarchyFilters()}
              setChartdata={returnSetChartdata()}
              setFetchCall={returnSetFetchCall()}
              HFilters={HFilters}
              CFilters={CFilters}
              setLoader={setLoader}
              selectedCheckboxesC={selectedCheckboxesC}
              setSelectedCheckboxesC={setSelectedCheckboxesC}
              selectedCheckboxes={selectedCheckboxes}
              setSelectedCheckboxes={setSelectedCheckboxes}
            />
          ) : null}

          {ActiveChart === "Trend_Analysis" ? (
            <TrendAnalysis
              hierarchySelectionBreadCrumb={hierarchySelectionBreadCrumb}
              channelSelectionBreadCrumb={channelSelectionBreadCrumb}
              timeframe={timeframeTrends}
              setTimeframe={setTimeframeTrends}
              onChangeHandler={onChangeHandlerTrends}
              TimeFramesAll={TimeFramesAllTrends}
              DefaultStartDate={DefaultStartDateTrends}
              DefaultEndDate={DefaultEndDateTrends}
              setDefaultStartDate={setDefaultStartDateTrends}
              setDefaultEndDate={setDefaultEndDateTrends}
              FromDatesAll={FromDatesAllTrends}
              ToDatesAll={ToDatesAllTrends}
              setFetchCall={setFetchCallTrends}
              Summary={Summary}
              AllFilters={AllFilters}
              ActiveFilters={ActiveFilters}
              FooterNotes={FooterNotes}
              categories={categoriesTrends}
              series={seriesTrends}
              xtitle={xtitleTrends}
              ytitle={ytitleTrends}
              charttitle={charttitleTrends}
              // DrillUp={DrillUpTrends}
              DrillDown={DrillDownTrends}
              DrillUpAll={DrillUpAllTrends}
              DrillDownAll={DrillDownAllTrends}
              DrillAll={DrillAllTrends}
              ResetAll={ResetAllTrends}
              BreadCrumbs={BreadCrumbsTrends}
              // setZoomIn={setZoomIn}
              HandleZoom={HandleZoom}
              ZoomIn={ZoomIn}
              NotAllow={NotAllow}
              ShowSummary={ShowSummary}
              // setDownloadchart={setDownloadchart}
              // Downloadchart={Downloadchart}
              setLoader={setLoader}
              Fixed={Fixed}
              showLegend={showLegend}
              setIsDownloadingChart={setIsDownloadingChart}
              setChartContainerID={setChartContainerID}
              setDownloadedImageName={setDownloadedImageName}
              setDownloadingChartTitle={setDownloadingChartTitle}
              setHierarchy={setHierarchy}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setTimeFrameChart={setTimeFrameChart}
              downloadDataT={downloadDataT}
              downloadPDFT={downloadPDFT}
              startdate={startdate}
              enddate={enddate}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Main;
